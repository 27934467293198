import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {CampaignsService} from '../../../../services/campaigns.service';
import {CampaignsInterface} from '../../../../interfaces/campaigns-interface';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import { Subscription } from 'rxjs';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';
import { TranslateService } from '@ngx-translate/core';
import { NewCampaignStep1Component } from '../new-campaign-step1/new-campaign-step1.component';

@Component({
  selector: 'app-campaigns-modal',
  templateUrl: './campaigns-modal.component.html',
  styleUrls: ['./campaigns-modal.component.scss'],
})
export class CampaignsModalComponent implements OnInit, OnDestroy {
  loading: boolean;
  addingInfluencers = false;
  isCheckedRecently = false;
  isCheckedRecommended = true;
  isCheckedNewInfluencers = true;

  selectedCampaignIndex;

  campaigns: Array<CampaignsInterface>;

  influencersAnalyzedInfo = {
    "recommended_update": [],
    "recently_updated": [],
    "not_analyzed": [],
    "required_tokens": 0
  };

  private subscriptions: Subscription[] = [];

  constructor(
    private _campaignsService: CampaignsService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CampaignsModalComponent>,
    public snackBar: MatSnackBar,
    public router: Router,
    private translate: TranslateService,
  ) {
    this.loading = true;
  }

  ngOnInit() {
    this.getCampaigns();
    this.getInfluencersAnalyzedInfo();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  toggleChecked(property: 'isCheckedRecently' | 'isCheckedRecommended') {
    this[property] = !this[property];
    this.getTokensRequired(property, this[property]);
  }

  getTokensRequired(property: string, checked: boolean) {
    let updateValue = 0;
  
    if (property === 'isCheckedRecently') {
      updateValue = this.influencersAnalyzedInfo.recently_updated.length;
    } else if (property === 'isCheckedRecommended') {
      updateValue = this.influencersAnalyzedInfo.recommended_update.length;
    }
  
    if (updateValue !== 0) {
      this.influencersAnalyzedInfo.required_tokens += checked ? updateValue : -updateValue;
    }
  }
  

  getInfluencersAnalyzedInfo() {
    const influencer_ids = this.data.influencers 
      ? this.data.influencers.map(influencer => influencer.influencer_id) 
      : [this.data.influencer_id];
  
    this.subscriptions.push(
      this._campaignsService.getInfluencersAnalyzedInfo(influencer_ids).subscribe(response => {
        this.influencersAnalyzedInfo = response.body;
  
        this.isCheckedRecommended = this.influencersAnalyzedInfo.recommended_update.length > 0;
        // this.isCheckedRecently = this.influencersAnalyzedInfo.recently_updated.length > 0;
        this.isCheckedNewInfluencers = this.influencersAnalyzedInfo.not_analyzed.length > 0;

        this.loading = false;

      }, () => {
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
          data: { message: this.translate.instant('customSnackbar.thereHasBeenError'), type: 'error' },
          duration: 4000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: 'error-snackbar',
        });
        this.dialogRef.close();
      })
    );
  }
  

  getCampaigns() {
    let influencerId = undefined;
  
    if (typeof this.data.influencer_id === 'object' && Array.isArray(this.data.influencer_id)) {
      influencerId = undefined;
    } else {
      influencerId = this.data.influencer_id;
    }

    if (this.data.influencers) {
      if (this.data.influencers.length === 1) {
        influencerId = this.data.influencers[0].influencer_id;
      }
    }

    this.subscriptions.push(
        this._campaignsService
        .getCampaigns(undefined, influencerId)
        .subscribe(
          result => {
            this.campaigns = result;
          },
          () => {
            this.campaigns = [];
          }
        )
        .add(() => {
          this.addingInfluencers = false;
        }) 
    );
  }

   isArray(variable: any): boolean {
    return Array.isArray(variable);
  }
  

  openNewCampaignDialog(): void {
    // Inicializar el array de influencers con el influencer dado o como un array vacío
    let influencers = this.data.influencers || [
      { 
        influencer_id: this.data.influencer_id,
        full_name: this.data.full_name,
        network: this.data.network,
        profile_pic_url: this.data.profile_pic_url,
        username: this.data.username
      }
    ];
  
    const influencersForUpdate = new Map(); // Usar un Map para una búsqueda más rápida
  
    // Función auxiliar para añadir influencers al Map
    const addInfluencersForUpdate = (influencersArray, forceAnalyze) => {
        if (Array.isArray(influencersArray)) {
            influencersArray.forEach(id => {
                influencersForUpdate.set(id, {
                    influencer_id: id,
                    force_analyze: forceAnalyze
                });
            });
        }
    };
  
    // Añadir influencers a actualizar según las condiciones
    addInfluencersForUpdate(this.influencersAnalyzedInfo.not_analyzed, true);
  
    if (this.isCheckedRecommended) {
        addInfluencersForUpdate(this.influencersAnalyzedInfo.recommended_update, true);
    } else if (this.isCheckedRecently) {
        addInfluencersForUpdate(this.influencersAnalyzedInfo.recently_updated, true);
    }
  
    // Actualizar influencersForUpdate con la información adicional de los influencers existentes
    influencers.forEach(influencer => {
        if (influencersForUpdate.has(influencer.influencer_id)) {
            // Actualizar el objeto existente en el Map
            const existingInfluencer = influencersForUpdate.get(influencer.influencer_id);
            existingInfluencer.network = influencer.network;
            existingInfluencer.profile_pic_url = influencer.profile_pic_url;
            existingInfluencer.full_name = influencer.full_name;
            existingInfluencer.username = influencer.username;
        } else {
            // Añadir nuevo influencer al Map
            influencersForUpdate.set(influencer.influencer_id, {
                influencer_id: influencer.influencer_id,
                force_analyze: false,
                network: influencer.network,
                profile_pic_url: influencer.profile_pic_url,
                full_name: influencer.full_name,
                username: influencer.username
            });
        }
    });
  
    // Convertir el Map a un array para el servicio
    const influencersForUpdateArray = Array.from(influencersForUpdate.values());

    this.dialogRef.close();
    const dialogRef = this.dialog.open(NewCampaignStep1Component, {
      width: '625px',
      height: '625px',
        data: {
            influencers: influencersForUpdateArray,
        },
        panelClass: 'remove-padding',
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res.status === 'added') {
        this.getCampaigns();
      }
    });
  }

  addInfluencerToCampaign(campaign_id, influencer_id, index) {
    this.addingInfluencers = true;

    // Inicializar el array de influencers con el influencer dado o como un array vacío
    let influencers = this.data.influencers || [
      { 
        influencer_id: influencer_id,
        full_name: this.data.full_name,
        network: this.data.network,
        profile_pic_url: this.data.profile_pic_url,
        username: this.data.username
      }
    ];
  
    const influencersForUpdate = new Map(); // Usar un Map para una búsqueda más rápida
  
    // Función auxiliar para añadir influencers al Map
    const addInfluencersForUpdate = (influencersArray, forceAnalyze) => {
        if (Array.isArray(influencersArray)) {
            influencersArray.forEach(id => {
                influencersForUpdate.set(id, {
                    influencer_id: id,
                    force_analyze: forceAnalyze
                });
            });
        }
    };
  
    // Añadir influencers a actualizar según las condiciones
    addInfluencersForUpdate(this.influencersAnalyzedInfo.not_analyzed, true);
  
    if (this.isCheckedRecommended) {
        addInfluencersForUpdate(this.influencersAnalyzedInfo.recommended_update, true);
    } else if (this.isCheckedRecently) {
        addInfluencersForUpdate(this.influencersAnalyzedInfo.recently_updated, true);
    }
  
    // Actualizar influencersForUpdate con la información adicional de los influencers existentes
    influencers.forEach(influencer => {
        if (influencersForUpdate.has(influencer.influencer_id)) {
            // Actualizar el objeto existente en el Map
            const existingInfluencer = influencersForUpdate.get(influencer.influencer_id);
            existingInfluencer.network = influencer.network;
            existingInfluencer.profile_pic_url = influencer.profile_pic_url;
            existingInfluencer.full_name = influencer.full_name;
            existingInfluencer.username = influencer.username;
        } else {
            // Añadir nuevo influencer al Map
            influencersForUpdate.set(influencer.influencer_id, {
                influencer_id: influencer.influencer_id,
                force_analyze: false,
                network: influencer.network,
                profile_pic_url: influencer.profile_pic_url,
                full_name: influencer.full_name,
                username: influencer.username
            });
        }
    });
  
    // Convertir el Map a un array para el servicio
    const influencersForUpdateArray = Array.from(influencersForUpdate.values());
  
    this.subscriptions.push(
        this._campaignsService.addInfluencerToCampaign(campaign_id, influencersForUpdateArray).subscribe(
            result => {
                this.getCampaigns();
                this.addingInfluencers = false;
                this.selectedCampaignIndex = index;
                this.snackBar.openFromComponent(CustomSnackbarComponent, {
                    data: { message: result.body.message, type: 'success' },
                    duration: 5000,
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                    panelClass: 'success-snackbar'
                });
            },
            () => {
                this.addingInfluencers = false;
                this.snackBar.openFromComponent(CustomSnackbarComponent, {
                    data: { message: this.translate.instant('customSnackbar.thereHasBeenError'), type: 'error' },
                    duration: 3000,
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                    panelClass: 'error-snackbar'
                });
            }
        )
    );
}


  viewCampaign(campaign_id) {
    this.router.navigate(['/app/campaign', campaign_id]);
    this.dialogRef.close();
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BriefService } from '../../../../../../../../services/brief.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-modal-add-link-influencer',
  templateUrl: './modal-add-link-influencer.component.html',
  styleUrls: ['./modal-add-link-influencer.component.scss']
})
export class ModalAddLinkInfluencerComponent implements OnInit {

  loading = false;
  influencerLinkForm: FormGroup;

  constructor(private _briefService: BriefService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ModalAddLinkInfluencerComponent>,
    private translate: TranslateService,

  ) { }

  ngOnInit() {
    this.influencerLinkForm = new FormGroup({
      link: new FormControl('', [Validators.required,  Validators.pattern('^(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$')
      ])
    });
    if (this.data.link !== '') {
      this.influencerLinkForm.controls['link'].setValue(this.data.link);
    }
  }


  addInfluencerLink() {
    this.loading = true;
    const dict_attribute = {};
    // Si viene directamente en this.data
    if (!this.data.influencers) {
      dict_attribute['influencers'] = [{
        correction_index: this.data.correction_index,
        custom_destination_url: this.influencerLinkForm.controls['link'].value,
        description: this.data.description,
        influencer_id: this.data.influencer_id,
      }];
    } else {
      // Si viene como un array en this.data.influencers
      dict_attribute['influencers'] = this.data.influencers.map((influencer: any) => ({
        correction_index: influencer.correction_index || this.data.correction_index,
        custom_destination_url: influencer.link || this.influencerLinkForm.controls['link'].value,
        description: influencer.description || this.data.description,
        influencer_id: influencer.influencer_id || this.data.influencer_id,
      }));
    }
    this._briefService.updateBrief(this.data.campaign_id, dict_attribute).subscribe(() => {
      this.loading = false;
      this.snackBar.openFromComponent(CustomSnackbarComponent, {
        data: { message: this.translate.instant('modalAddLinkInfluencer.linkSavedSuccess'), type: 'success' },
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: 'success-snackbar'
    });
      this.dialogRef.close({ status: 'success', link: this.influencerLinkForm.controls['link'].value });
      this.data.custom_destination_url = this.influencerLinkForm.controls['link'].value;
    }, () => {
      this.loading = false;
      this.snackBar.openFromComponent(CustomSnackbarComponent, {
        data: { message: this.translate.instant('shareCampaignPlanModal.oopsError'), type: 'error' },
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: 'error-snackbar',
      });
    });
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { NewCampaignComponent } from '../new-campaign/new-campaign.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-campaign-step1',
  templateUrl: './new-campaign-step1.component.html',
  styleUrls: ['./new-campaign-step1.component.scss']
})
export class NewCampaignStep1Component implements OnInit {

  steps = [
    { icon: 'list_alt', label: this.translate.instant('stepper.campaignPlan') },
    { icon: 'chat', label: this.translate.instant('stepper.campaignBrief') },
    { icon: 'view_kanban', label: this.translate.instant('stepper.campaignProgress') },
    { icon: 'insert_chart', label: this.translate.instant('stepper.campaignReport') }
  ];

  flowSelectedForm: FormGroup;


  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<NewCampaignStep1Component>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    
  ) { 
    this.flowSelectedForm = new FormGroup({
      'complete': new FormControl(false),
      'management': new FormControl(false),
      'report': new FormControl(false),
    });
  }

  ngOnInit() {
  }

  selectFlow(flowName) {
    if (this.flowSelectedForm.controls[flowName].value === true) {
      this.flowSelectedForm.controls[flowName].setValue(false);
    } else {
      this.flowSelectedForm.controls[flowName].setValue(true);
    }
    if (flowName === 'complete') {
      this.flowSelectedForm.controls['management'].setValue(false);
      this.flowSelectedForm.controls['report'].setValue(false);
    } else if (flowName === 'management') {
      this.flowSelectedForm.controls['complete'].setValue(false);
      this.flowSelectedForm.controls['report'].setValue(false);
    } else if (flowName === 'report') {
      this.flowSelectedForm.controls['management'].setValue(false);
      this.flowSelectedForm.controls['complete'].setValue(false);
    }
  }



  goToStep2() {
    let flowSelected = '';
    if (this.flowSelectedForm.controls['complete'].value === true ) {
      flowSelected = this.translate.instant('campaigns.newCampaignModal.completeFlow');
    } else if (this.flowSelectedForm.controls['management'].value === true) {
      flowSelected = this.translate.instant('campaigns.newCampaignModal.managementAndReport');
    } else if (this.flowSelectedForm.controls['report'].value === true) {
      flowSelected = this.translate.instant('campaigns.newCampaignModal.report');
    }
    const dialogRef = this.dialog.open(NewCampaignComponent, {
      width: '625px',
      height: '625px',
      data: {
        influencers: this.data.influencers,
        flowSelected: flowSelected,
        createCampaign: true,
        planNameShown: this.data.planNameShown
      },
      panelClass: 'remove-padding',
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        if (res['status'] === 'created' || res.status === 'added') {
          const result_dialog = {
            status: res['status'],
            refresh: res['refresh']
          };
          this.dialogRef.close(result_dialog);
        } 
      } else {
        this.dialogRef.close();
      }
    });
  }

}

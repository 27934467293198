import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {ModalAdvantagesPerformancePlanComponent} from '../../modals/modal-advantages-performance-plan/modal-advantages-performance-plan.component';
import {State} from 'src/app/store';
import {Store} from '@ngrx/store';
import * as selectorsAuth from '../../../../../../store/auth/authentication.selectors';
import isEmpty from 'lodash/isEmpty';
import {ModalViewCodeConversionsLinksComponent} from '../../modals/modal-view-code-conversions-links/modal-view-code-conversions-links.component';
import {ShowInspirationalModalComponent} from '../../../show-inspirational-modal/show-inspirational-modal.component';
import {environment} from '../../../../../../../environments/environment';
import {AmplifyService} from 'aws-amplify-angular';
import {BriefStep1Component} from '../brief-step1/brief-step1.component';


@Component({
  selector: 'app-brief-step2',
  templateUrl: './brief-step2.component.html',
  styleUrls: ['./brief-step2.component.scss']
})
export class BriefStep2Component implements OnInit, OnChanges {

  @Input() campaign_id;
  @Input() has_report;
  @Input() inspirational_media;
  @Input() campaign_general_description;
  @Input() payment_type;
  @Input() send_gift_influencer;

  @Output() callSaveChanges = new EventEmitter<any>();
  @Output() callActivateSendProposalsButton = new EventEmitter<any>();

  upload_inspirational_media = false;

  breakpoint;

  error_inspirational_media_upload = '';
  planNameShown = '';

  update_form: FormGroup;

  constructor(private dialog: MatDialog,
              public store: Store<State>,
              private _amplifyService: AmplifyService,
  ) {
    this.update_form = new FormGroup({
      send_gift_influencer: new FormControl('false'),
      campaign_general_description: new FormControl('', [Validators.required]),
    });
  }

  static setFileNameTimestamp(file_name) {
    const current_timestamp = new Date().getTime();
    const file_name_array = file_name.split('.');
    return (
        file_name_array[0] +
        '_' +
        current_timestamp.toString() +
        '.' +
        file_name_array[1]
    );
  }

  ngOnInit() {
    if (!this.inspirational_media) {
      this.inspirational_media = [];
    }
    this.breakpoint = (window.innerWidth <= 400) ? 1 : 7;
    this.store.select(selectorsAuth.selectAccessSets).subscribe(user => {
      this.planNameShown = user.PLAN_NAME_SHOWN;
    });
    if (this.campaign_general_description !== undefined) {
      this.update_form.controls['campaign_general_description'].setValue(
          this.campaign_general_description
      );
    }

    if (this.send_gift_influencer !== undefined) {
      this.update_form.controls['send_gift_influencer'].setValue(
          this.send_gift_influencer
      );
    }

    if (this.has_report) {
      this.update_form.controls['send_gift_influencer'].disable();
      this.update_form.controls['campaign_general_description'].disable();
    }
    this.controlActivateSendProposalsButton();
  }



  /**
   * Update the brief, send all params from the form and the service select the correct values and update brief
   */
  saveChanges(attributeName, attributeValue) {
    this.callSaveChanges.emit({'attributeName': attributeName, 'attributeValue': attributeValue});
  }


  openModalAdvantagesPerformancePlan() {
    this.dialog.open(ModalAdvantagesPerformancePlanComponent, {
      width: '30%',
      height: 'auto',
      data: {},
    });
  }


  openShowInspirationalDialog(media): void {
    this.dialog.open(ShowInspirationalModalComponent, {
      width: 'auto',
      height: '100vh',
      autoFocus: false,
      data: { media },
      backdropClass: 'story-dialog-backdrop-class',
      panelClass: 'story-dialog-panel-class',
    });
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 6;
  }

  removeInspirationalMedia(inspirational_url) {
    let index = -1;
    this.inspirational_media.forEach((inspirational_media, idx) => {
      if (inspirational_media.url === inspirational_url) {
        index = idx;
      }
    });

    if (index > -1) {
      this.inspirational_media.splice(index, 1);
    }

    this.callSaveChanges.emit({'attributeName': 'inspirational_media', 'attributeValue': this.inspirational_media});
  }


  uploadInspirationalPicture(fileInput: any): void {
    this.upload_inspirational_media = true;
    this.error_inspirational_media_upload = '';
    const media_file = fileInput.target.files[0];

    if (
        media_file.type === 'video/mp4' ||
        media_file.type === 'video/quicktime'
    ) {
      if (media_file.size <= 70 * 1024 * 1024) {
        this.settingUpMediaForSaving(media_file, true);
      } else {
        this.error_inspirational_media_upload =
            'The supported files are videos up to 70MB as ".mp4" or ".mov" and images up to 10MB as ".jpg", ".jpeg" or ".png".';
        this.upload_inspirational_media = false;
      }
    } else if (
        media_file.type === 'image/png' ||
        media_file.type === 'image/jpeg' ||
        media_file.type === 'image/jpg'
    ) {
      if (media_file.size <= 10 * 1024 * 1024) {
        this.settingUpMediaForSaving(media_file, false);
      } else {
        this.error_inspirational_media_upload =
            'The supported files are videos up to 70MB as ".mp4" or ".mov" and images up to 10MB as ".jpg", ".jpeg" or ".png".';
        this.upload_inspirational_media = false;
      }
    } else {
      this.error_inspirational_media_upload =
          'Unsupported file type. Supported file types are: jpg, jpeg, png, mp4 and mov';
      this.upload_inspirational_media = false;
    }
  }

  settingUpMediaForSaving(media_file, is_video) {
    const file_name = media_file.name;
    let new_name = file_name;

    const config = {
      bucket: environment.aws_resources.s3.buckets.inspirational_pictures,
      region: environment.aws_resources.region,
      level: 'public',
      customPrefix: {
        public: '',
      },
    };

    this._amplifyService
        .storage()
        .vault.list(this.campaign_id + '/', config)
        .then(resp => {
          if (resp.length !== 0) {
            resp.forEach(media => {
              if (media.key === this.campaign_id + '/' + file_name) {
                new_name = BriefStep2Component.setFileNameTimestamp(file_name);
              }
            });
          }

          this.savingInspirationalMedia(new_name, media_file, is_video);
        })
        .catch(() => {
          this.error_inspirational_media_upload =
              'Oops, something went wrong. Please try again later.';
        });
  }


  savingInspirationalMedia(file_name, file, is_video) {
    const config = {
      bucket: environment.aws_resources.s3.buckets.inspirational_pictures,
      region: environment.aws_resources.region,
      level: 'public',
      customPrefix: {
        public: '',
      },
    };

    this._amplifyService
        .storage()
        .vault.put(this.campaign_id + '/' + file_name, file, config)
        .then(() => {
          const url =
              'https://s3-eu-west-1.amazonaws.com/' +
              config.bucket +
              '/' +
              this.campaign_id +
              '/' +
              file_name;

          const media = {
            url: url,
            is_video: is_video,
          };

          this.inspirational_media.push(media);

          this.saveChanges('inspirational_media', this.inspirational_media);

          this.upload_inspirational_media = false;
        })
        .catch(() => {
          this.error_inspirational_media_upload =
              'Oops, something went wrong. Please try again later.';
          this.upload_inspirational_media = false;
        });
  }

  ngOnChanges() {
    this.controlActivateSendProposalsButton();
  }

  controlActivateSendProposalsButton() {
    if (
        this.update_form.controls['campaign_general_description'].value === ''
    ) {
      this.callActivateSendProposalsButton.emit(false);
    } else {
      this.callActivateSendProposalsButton.emit(true);
    }
  }
}

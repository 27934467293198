import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {BriefCampaignForUpdateClass} from '../../../../classes/brief-campaign-for-update-class';
import {ActivatedRoute, Router} from '@angular/router';
import {BriefService} from '../../../../services/brief.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {BriefCampaignForUpdateInterface, InspirationalMedia} from '../../../../interfaces/brief-campaign-for-update-interface';
import {AmplifyService} from 'aws-amplify-angular';
import {AuthenticatedUser} from '../../../../classes/authenticated-user-class';
import {Intercom} from 'ng-intercom';
import {Store} from '@ngrx/store';
import {State} from 'src/app/store';
import * as selectors from '../../../../store/auth/authentication.selectors';
import * as selectorsAuth from '../../../../store/auth/authentication.selectors';
import {AccessSets} from '../../../../types/access-sets';
import {ModalViewCodeConversionsLinksComponent} from './modals/modal-view-code-conversions-links/modal-view-code-conversions-links.component';
import * as selectorsApp from '../../../../store/app/app.selectors';
import {selectAppViewPort} from '../../../../store/app/app.selectors';
import {ModalAdvantagesPerformancePlanComponent} from './modals/modal-advantages-performance-plan/modal-advantages-performance-plan.component';


@Component({
  selector: 'app-brief-campaign',
  templateUrl: './brief-campaign.component.html',
  styleUrls: ['./brief-campaign.component.scss'],
})
export class BriefCampaignComponent implements OnInit, OnDestroy {
  // General variables
  loading = false;
  campaign_id = '';
  campaign_name = '';
  brand_logo = '';
  inspirational_media: Array<InspirationalMedia> = [];
  upload_inspirational_media = false;
  error_inspirational_media_upload = '';

  total_cost = 0;
  waiting_approval = 0;
  currency_type = '';
  // Date variables
  start_date_before_today: boolean;
  start_date_under_one_week_from_today: boolean;
  campaign_extended = false;
  canAiBrief : boolean;

  // Back-end objects
  brief: any;
  influencers: any;
  payment: any;
  cpaLinks: any;
  notviewCode = false;

  // External
  authenticated_user: AuthenticatedUser;
  brief_for_update: BriefCampaignForUpdateInterface;


  userIsExecute = false;
  userAccessSets: AccessSets;
  private subscriptions = [];

  real_owner_email = '';
  planNameShown = '';
  groupName = '';
  breakpoint;
  viewport;


  stepSelected = 'step1';
  influencerPreviewSelected;
  show_stories_url: boolean;
  activateSendProposalsButton: boolean;
  activateCreateReportButton: boolean;

  constructor(
    private _briefService: BriefService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,
    public router: Router,
    private _amplifyService: AmplifyService,
    private intercom: Intercom,
    public store: Store<State>,

  ) {
    this.loading = true;
    this.campaign_id = this.activatedRoute.params['value'].id;

    this.brief_for_update = new BriefCampaignForUpdateClass();
    // this.getAllAttributes();
  }

  static getExtensionFromImage(file_type: string): string {
    return file_type.replace('image/', '.');
  }

  static checkAtLeastOneAddedOrWaitingPaymentStatus(influencers): boolean {
    return influencers.some(influencer => {
      if (
        influencer.state === 'added' ||
        influencer.state === 'waiting_payment'
      ) {
        return true;
      }
    });
  }

  static setFileNameTimestamp(file_name) {
    const current_timestamp = new Date().getTime();
    const file_name_array = file_name.split('.');
    return (
      file_name_array[0] +
      '_' +
      current_timestamp.toString() +
      '.' +
      file_name_array[1]
    );
  }

  // async getAllAttributes() {
  //   const user = await this._amplifyService
  //     .auth()
  //     .currentAuthenticatedUser({ bypassCache: true });
  //   this.authenticated_user = new AuthenticatedUser(user);
  //   if (this.authenticated_user.attributes === undefined) {
  //     this.logout();
  //   }
  // }

  ngOnInit() {
    this.breakpoint = (window.innerWidth <= 400) ? 1 : 7;
    this.subscriptions.push(
      this.store.select(selectAppViewPort).subscribe(result => {
        this.viewport = result;
      })
    );
    this.getBrief();

    this.subscriptions.push(
      this.store.select(selectors.selectAccessSets).subscribe(access => {
        this.userAccessSets = access;
        this.canAiBrief = access.CAN_AI_BRIEF_MAIN;
        this.manageDropdown('CAN_INVITE_INFLUENCERS');
      }),
    this.store.select(selectorsAuth.selectAccessSets).subscribe(user => {
      this.planNameShown = user.PLAN_NAME_SHOWN;
    }),
    this.store.select(selectorsApp.selectUserGroup).subscribe(userGroup => {
      if (userGroup === null ) {
        this.groupName = environment.platform_id;
      } else {
        this.groupName = userGroup.group_name;
      }
    })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }



  manageDropdown(key: string): void {
    if (Object.keys(this.userAccessSets).includes(key)) {
      this.userIsExecute = true;
    }
  }

  getBrief() {
    this._briefService.getBrief(this.campaign_id).subscribe(
      result => {
        if (result['status'] === 200) {
          this.brief = result['body'];
          this.currency_type = this.brief.currency_code;
          if ('real_owner_email' in this.brief) {
            this.real_owner_email = this.brief['real_owner_email'];
          }
          const clean_influencers_list = [];
          // this.getDates();
          this.brief.influencers.reverse().forEach((influencer, idx, array) => {
            if (influencer.state !== 'backup') {
              clean_influencers_list.push(influencer);
            }
          });
          this.brief.influencers = clean_influencers_list;
          if (this.brief.influencers.length === 0) {
            this.router.navigate(['/app/campaign/' + this.campaign_id]);
          }
          this.refreshBriefData();
        }
      },
      error => {
        if (
          error.status.toString() === '404' ||
          error.status.toString() === '400'
        ) {
          this.router.navigate(['/app/campaigns']);
        }
      }
    );
  }

  refreshBriefData() {
    if (this.brief.date_end_extended !== undefined) {
      this.campaign_extended = true;
      this.brief.date_end = this.brief.date_end_extended;
    }
    this.campaign_name = this.brief.campaign_name;
    this.influencers = this.brief.influencers;
    for (let i = 0; i < this.influencers.length; i++) {
      this.influencers[i].total_cost = this.getInfluencerTotalCost(
          this.influencers[i]
      );
      if (this.influencers[i].state === 'added') {
        this.brief_for_update.influencers.push({
          influencer_id: this.influencers[i].influencer_id,
          description:
              this.influencers[i].description !== undefined
                  ? this.influencers[i].description
                  : '',
          correction_index: this.influencers[i].correction_index,
          custom_destination_url: this.influencers[i].custom_destination_url,
        });
      }
    }
    this.start_date_under_one_week_from_today =
        new Date(this.brief.date_start).getTime() <
        new Date().getTime() + 60 * 60 * 24 * 1000 * 5;
    this.influencerPreviewSelected = this.influencers[0];
    this.show_stories_url = !!(this.influencerPreviewSelected.custom_destination_url || this.brief.destination_url);
    this.loading = false;
  }

  getInfluencerTotalCost(influencer) {
    if (influencer.network === 'instagram') {
      const number_posts = influencer.instagram_services.posts;
      const number_stories = influencer.instagram_services.stories;
      const price_post = influencer.stats.price_per_post_plus_perc;
      const price_story = influencer.stats.price_per_story_plus_perc;
      return (
          (number_posts * price_post + number_stories * price_story) *
          influencer.correction_index
      );
    } else if (influencer.network === 'youtube') {
      const number_videos = influencer.youtube_services.videos;
      const number_mentions = influencer.youtube_services.mentions;
      const price_video = influencer.stats.price_per_video_plus_perc;
      const price_mention = influencer.stats.price_per_mention_plus_perc;
      return (
          (number_videos * price_video + number_mentions * price_mention) *
          influencer.correction_index
      );
    } else if (influencer.network === 'tiktok') {
      const number_videos = influencer.tiktok_services.tiktok_videos;
      const price_video = influencer.stats.price_per_tiktok_video_plus_perc;
      return (
          number_videos * price_video *
          influencer.correction_index
      );
    }
  }

  /**
   * Update the brief, send all params from the form and the service select the correct values and update brief
   */
  saveChanges(attribute) {
    const attributeForUpdate = {};
    attributeForUpdate[attribute.attributeName] = attribute.attributeValue;
    this._briefService.updateBrief(this.campaign_id, attributeForUpdate ).subscribe(
      () => {
        this.brief[attribute.attributeName] = attribute.attributeValue;
      },
      () => {
        this.openSnackBar('Error saving brief, please try again soon', 'Close');
      }
    );
  }


  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }


  settingUpMediaForSaving(media_file, is_video) {
    const file_name = media_file.name;
    let new_name = file_name;

    const config = {
      bucket: environment.aws_resources.s3.buckets.inspirational_pictures,
      region: environment.aws_resources.region,
      level: 'public',
      customPrefix: {
        public: '',
      },
    };

    this._amplifyService
      .storage()
      .vault.list(this.campaign_id + '/', config)
      .then(resp => {
        if (resp.length !== 0) {
          resp.forEach(media => {
            if (media.key === this.campaign_id + '/' + file_name) {
              new_name = BriefCampaignComponent.setFileNameTimestamp(file_name);
            }
          });
        }

        this.savingInspirationalMedia(new_name, media_file, is_video);
      })
      .catch(() => {
        this.error_inspirational_media_upload =
          'Oops, something went wrong. Please try again later.';
      });
  }

  savingInspirationalMedia(file_name, file, is_video) {
    const config = {
      bucket: environment.aws_resources.s3.buckets.inspirational_pictures,
      region: environment.aws_resources.region,
      level: 'public',
      customPrefix: {
        public: '',
      },
    };

    this._amplifyService
      .storage()
      .vault.put(this.campaign_id + '/' + file_name, file, config)
      .then(() => {
        const url =
          'https://s3-eu-west-1.amazonaws.com/' +
          config.bucket +
          '/' +
          this.campaign_id +
          '/' +
          file_name;

        const media = {
          url: url,
          is_video: is_video,
        };

        this.inspirational_media.push(media);

        this.saveChanges('inspirational_media');

        this.upload_inspirational_media = false;
      })
      .catch(() => {
        this.error_inspirational_media_upload =
          'Oops, something went wrong. Please try again later.';
        this.upload_inspirational_media = false;
      });
  }

  removeInspirationalMedia(inspirational_url) {
    let index = -1;
    this.brief.inspirational_media.forEach((inspirational_media, idx) => {
      if (inspirational_media.url === inspirational_url) {
        index = idx;
      }
    });

    if (index > -1) {
      this.brief.inspirational_media.splice(index, 1);
    }

  }

  setVirtualScrollHeight(array_length) {
    if (this.viewport === 'handset') {
      if (array_length >= 10) {
        return '950px';
      } else {
        return (array_length * 500).toString() + 'px';
      }
    } else {
      if (array_length >= 10) {
        return '1300px';
      } else {
        return (array_length * 155).toString() + 'px';
      }
    }
  }


  /**
   * Abrir modal que muestra la info que tienen que copiar
   * conversion links en formato <script>
   */
  viewCodeConversionLinks () {
    this.dialog.open(ModalViewCodeConversionsLinksComponent, {
      width: '500px',
      height: 'auto',
      autoFocus: false,
      data: {
        conversions: this.cpaLinks
      }
    });
  }


  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 6;
  }

  openModalAdvantagesPerformancePlan() {
    this.dialog.open(ModalAdvantagesPerformancePlanComponent, {
      width: '30%',
      height: 'auto',
      data: {},
    });
  }

  logout(): void {
    this.intercom.shutdown();
    this._amplifyService
        .auth()
        .signOut()
        .then(() => {
          this.router.navigate(['/auth/login']);
        });
  }

  changeShowStoriesUrl(event) {
    this.show_stories_url = event;
  }

  callInfluencerSelected(event) {
    this.influencerPreviewSelected = event;
  }

  callActiveSendProposalButton(event) {
    this.activateSendProposalsButton = event;
    let countAdded = 0;
    this.influencers.forEach((influencer) => {
      if (influencer.state === 'added') {
        countAdded += 1;
      }
    });
    if (this.brief.campaign_general_description) {
      this.activateSendProposalsButton = this.activateSendProposalsButton && countAdded > 0;
    } else {
      this.activateSendProposalsButton = false;
    }
  }

  callActiveCreateReportButton(event) {
    this.activateCreateReportButton = event;
    let countAdded = 0;
    this.influencers.forEach((influencer) => {
      if (influencer.state === 'added') {
        countAdded += 1;
      }
    });
    this.activateCreateReportButton = this.activateCreateReportButton && countAdded > 0;
  }


  scrollTo(element: any): void {
    this.stepSelected = element;
    (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

}

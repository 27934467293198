import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReportService } from '../../../../services/report.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AmplifyService } from 'aws-amplify-angular';
import { AuthenticatedUser } from '../../../../classes/authenticated-user-class';
import { Intercom } from 'ng-intercom';
import * as selectorsAuth from '../../../../store/auth/authentication.selectors';
import { Store } from '@ngrx/store';
import { State } from '../../../../store';

@Component({
  selector: 'app-report-campaign',
  templateUrl: './report-campaign.component.html',
  styleUrls: ['./report-campaign.component.scss'],
})
export class ReportCampaignComponent implements OnInit, OnDestroy {
  campaign_id: any;
  report: any;
  loading: boolean;
  showNextUpdate = false;

  authenticated_user: AuthenticatedUser;
  currency_type = '';
  currency_symbol = '';
  real_owner_email = '';
  planNameShown = '';

  emailAccount: string;

  timeForUpdate;

  private subscriptions = [];

  constructor(
    private _reportService: ReportService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public router: Router,
    private _amplifyService: AmplifyService,
    private intercom: Intercom,
    private store: Store<State>,
  ) {
    this.loading = true;
    this.campaign_id = this.activatedRoute.params['value'].id;
  }

  logout(): void {
    this.intercom.shutdown();
    this._amplifyService
      .auth()
      .signOut()
      .then(() => {
        this.router.navigate(['/auth/login']);
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectorsAuth.selectUser).subscribe(user => {
        this.emailAccount = user.username;
      }),
      this.store.select(selectorsAuth.selectAccessSets).subscribe(user => {
        this.planNameShown = user.PLAN_NAME_SHOWN;
      }),
    );
    this.getReport();
  }

  /**
   * @description
   * This void type method access the getReport Service and returns the report object if status is 200 or redirects to detail-campaign.
   * @usageNotes
   * It's used to load the report or to refresh the page when an action is used on the same so that you can see the new data.
   */
  getReport(): void {
    this._reportService.getReport(this.campaign_id).subscribe(
      result => {
        if (result['status'] === 200) {
          this.report = result['body'];
          if ('real_owner_email' in this.report) {
            this.real_owner_email = this.report['real_owner_email'];
          }
          this.currency_type = this.report.currency_code;
          this.currency_symbol = this.report.currency_symbol;
          this.loading = false;
          const todayDate = Date.now();
          const today = Math.floor(new Date(todayDate).getTime() / 1000);
          const nextUpdate = new Date(this.report.estimated_next_report_update).getTime();
          const diffMiliSeconds = nextUpdate - today;
          const horas = Math.floor(diffMiliSeconds / 3600);
          const minutos = Math.floor((diffMiliSeconds % 3600) / 60);
          this.timeForUpdate = horas + ':' + minutos;
          this.showNextUpdate = nextUpdate >= today;

        }
      },
      () => {
        this.router.navigate(['/app/campaigns']);
      }
    );
  }

  percent(estimated, current) {
    if (estimated === 0 && current > 0) {
      return 100;
    } else if (estimated === 0) {
      return 0;
    } else {
      return (current / estimated) * 100;
    }
  }


  trackByMedia(index, item) {
    return !item ? null : item.id;
  }

  /**
   * @description Show snackbar with the messages and actions granted
   * @param message
   * @param action
   */
  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}

import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalExtendCampaignComponent} from '../../modals/modal-extend-campaign/modal-extend-campaign.component';
import {MatDialog} from '@angular/material';
import {environment} from '../../../../../../../environments/environment';
import {AmplifyService} from 'aws-amplify-angular';
import {BriefService} from '../../../../../../services/brief.service';

@Component({
  selector: 'app-brief-step1',
  templateUrl: './brief-step1.component.html',
  styleUrls: ['./brief-step1.component.scss']
})
export class BriefStep1Component implements OnInit, OnChanges {

  // COMPONENT VARS
  update_form: FormGroup;
  upload_brand_logo = false;
  errorGeneratingMusicLink = false;
  generatingMusicLink = false;
  error_brand_logo_upload = '';

  // INPUT VARS
  @Input() campaign_id;
  @Input() brand_name;
  @Input() has_report;
  @Input() date_start;
  @Input() date_end;
  @Input() payment_type;
  @Input() language_for_emails;

  // OUTPUT VARS
  @Output() contacting_influencers = new EventEmitter<any>();
  @Output() callSaveChanges = new EventEmitter<any>();
  @Output() callActivateSendProposalsButton = new EventEmitter<any>();
  @Output() callActivateCreateReportButton = new EventEmitter<any>();

  musicLinks: string[] = [];

  constructor(
  ) {
    // Establish the Reactive Form
    this.update_form = new FormGroup({
      brand_name: new FormControl('', [Validators.required]),
      payment_type: new FormControl('', [Validators.required]),
      language_for_emails: new FormControl(''),
    });
  }

  ngOnInit() {;

    if (this.brand_name !== undefined) {
      this.update_form.controls['brand_name'].setValue(this.brand_name);
    }

    if (this.payment_type !== undefined) {
      this.update_form.controls['payment_type'].setValue(this.payment_type);
    }

    if (this.has_report) {
      this.update_form.controls['brand_name'].disable();
      this.update_form.controls['payment_type'].disable();
    }

    if (this.language_for_emails) {
      this.update_form.controls['language_for_emails'].setValue(this.language_for_emails);
    }

    this.controlActivateSendProposalsButton();
  }


  /**
   * Update the brief, send all params from the form and the service select the correct values and update brief
   */
  saveChanges(attributeName, attributeValue) {
    this.callSaveChanges.emit({'attributeName': attributeName, 'attributeValue': attributeValue});
  }


  /**
   * If dates or payment type changes that function updates attributes in dynamo.
   * @param event Optional param that contains the value in case of changes are in payment type.
   */
  onCalculatePriceVariableChanges(event): void {
    this.callSaveChanges.emit({'attributeName': 'payment_type', 'attributeValue': event});
  }


  ngOnChanges() {
    this.controlActivateSendProposalsButton();
  }

  controlActivateSendProposalsButton() {
    if (
        this.update_form.controls['brand_name'].value !== ''
    ) {
      this.callActivateCreateReportButton.emit(true);
      this.callActivateSendProposalsButton.emit(true);
    } else {
      this.callActivateCreateReportButton.emit(false);
      this.callActivateSendProposalsButton.emit(false);
    }
  }

}


import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {CampaignsService} from '../../../../../services/campaigns.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Store} from '@ngrx/store';
import {State} from '../../../../../store';
import * as selectorsAuth from '../../../../../store/auth/authentication.selectors';
import {ModalInfluencerDataInfoComponent} from '../../../../../components/share/shared-campaign/modals/modal-influencer-data-info/modal-influencer-data-info.component';
import {NoopScrollStrategy} from '@angular/cdk/overlay';
import {CustomSnackbarComponent} from '../../custom-snackbar/custom-snackbar.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-controller-campaign',
  templateUrl: './controller-campaign.component.html',
  styleUrls: ['./controller-campaign.component.scss']
})
export class ControllerCampaignComponent implements OnInit, OnDestroy {

  @Output() callGetCampaign = new EventEmitter<any>();

  @Input() campaign;
  @Input() campaign_id;
  @Input() is_share;
  @Input() CAN_UNIQUE_AUDIENCE;

  @ViewChild('sidenav', { static: false }) profile_sidenav: MatSidenav;

  campaign_name: string;
  component_name: string;
  currency_type: string;
  currency_symbol: string;
  planNameShown: string;
  tapSelected = 'influencers';

  campaignDataRealtime = [];

  loading: boolean;
  canCreatePresentation: boolean;
  loading_profile = false;

  bindable_influencer_data: any;
  total_followers = 0;
  total_cost = 0;
  total_posts = 0;
  total_stories = 0;
  // total_reels = 0;
  total_youtube_videos = 0;
  total_tiktok_videos = 0;
  total_mentions = 0;
  totalTiktokers = 0;
  totalInstagramers = 0;
  totalYoutubers = 0;
  total_est_reach = 0;
  additional_margin_for_modal = 0;
  earn_media_value = 0;
  avg_engagement_obj: {};
  avg_engagement_ig = 0;
  avg_engagement_yt = 0;
  avg_engagement_tiktok = 0;
  total_influencers_impressions = 0;
  total_impressions_calculated = 0;
  total_reach_calculated = 0;
  total_cost_per_mille = 0;
  total_cost_per_mille_top_country = 0;
  total_comments_campaign = 0;
  total_estimated_likes_campaign = 0;
  cost_per_engagement = 0;

  generalAudience = {
    audience_gender_percentage: {},
    age: {},
    location: {},
    inactive_followers_percentage: 0,
    total_campaign_impressions: 0,
    influencers_with_audience_count: 0,
    total_influencers_count: 0,
  };

  influencers: any = [];
  ready_influencers: any = [];
  backup_influencers: any = [];
  pending_influencers: any = [];
  not_available_influencers: any = [];

  private subscriptions = [];


  constructor(
      private _campaignsService: CampaignsService,
      private activatedRoute: ActivatedRoute,
      public router: Router,
      public snackBar: MatSnackBar,
      public dialog: MatDialog,
      public store: Store<State>,
      private translate: TranslateService,

  ) {
    this.component_name = this.activatedRoute.url['value'][0].path;
    this.campaign_id = this.activatedRoute.params['value'].id;
  }

  ngOnInit() {
    this.calculateCampaignData();
    this.activatedRoute.url.subscribe(url => {
      this.component_name = url[0].path;
    });
    this.activatedRoute.params.subscribe(params => {
      this.campaign_id = params.id;
    });
    this.store.select(selectorsAuth.selectAccessSets).subscribe(user => {
      this.planNameShown = user.PLAN_NAME_SHOWN;
      this.canCreatePresentation = user.GENERATE_CAMPAIGN_PRESENTATION;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  calculateCampaignData() {
    this.loading = true;
    this.additional_margin_for_modal = this.campaign.campaign_margin_agency;
    if (!this.campaign.hasOwnProperty('campaign_margin_agency') || this.component_name === 'campaign') {
      this.campaign.campaign_margin_agency = 1;
    }
    this.ready_influencers = [];
    this.backup_influencers = [];
    this.pending_influencers = [];
    this.not_available_influencers = [];

    if (this.campaign.hasOwnProperty('influencers')) {
      this.campaign.influencers.forEach(influencer => {
        if (influencer.state !== 'backup' && influencer.state !== 'WAITING_DATA' && influencer.state !== 'DATA_UNAVAILABLE') {
          influencer['warning'] = false;
          this.ready_influencers.push(influencer);
        } else if (influencer.state === 'backup') {
          this.backup_influencers.push(influencer);
        } else if (influencer.state === 'WAITING_DATA') {
          this.pending_influencers.push(influencer);
        } else if (influencer.state === 'DATA_UNAVAILABLE') {
          this.not_available_influencers.push(influencer);
        }
      });
    }
    if (this.pending_influencers.length > 0 || this.not_available_influencers > 0) {
      this.openModalInfluencerData();
    }
    this.setInfluencersBackupTotalCost();
    this.setGeneralCampaignInfo();
    this.currency_type = this.campaign.currency_code;
    this.currency_symbol = this.campaign.currency_symbol;
    this.loading = false;
  }


  openModalInfluencerData() {
    const dialogRef =  this.dialog.open(ModalInfluencerDataInfoComponent, {
      width: '650px',
      height: '400px',
      autoFocus: false,
      panelClass: 'remove-padding',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        influencers: this.pending_influencers.concat(this.not_available_influencers),
        total_influencers: this.campaign.influencers.length,
        campaign_id: this.campaign_id,
        has_brief: this.campaign.has_brief,
        has_progress: this.campaign.has_progress,
        is_share: this.is_share
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status != null) {
        if (result.status === 200) {
          this.getCampaign();
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: result.body.message, type: 'success' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'success-snackbar',
          });
        } else {
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: 'Oops something went wrong, please try again.', type: 'error' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'error-snackbar',
          });
        }
      }
    });
  }

  totalMedias(): void {
    this.total_posts = 0;
    this.total_stories = 0;
    // this.total_reels = 0;
    this.total_youtube_videos = 0;
    this.total_mentions = 0;
    this.total_tiktok_videos = 0;
    this.totalTiktokers = 0;
    this.totalYoutubers = 0;
    this.totalInstagramers = 0;

    for (let index = 0; index < this.ready_influencers.length; index++) {
      if (this.ready_influencers[index].state !== 'canceled' && this.ready_influencers[index].state !== 'rejected') {
        if (this.ready_influencers[index].network === 'instagram') {
          this.totalInstagramers += 1;
          this.total_posts += this.ready_influencers[index]['instagram_services'][
              'posts'
              ];
          this.total_stories += this.ready_influencers[index]['instagram_services'][
              'stories'
              ];
          // this.total_reels += this.ready_influencers[index]['instagram_services'][
          //     'reels'
          //     ];
        } else if (this.ready_influencers[index].network === 'youtube') {
          this.totalYoutubers += 1;
          this.total_youtube_videos += this.ready_influencers[index]['youtube_services'][
              'videos'
              ];
          this.total_mentions += this.ready_influencers[index]['youtube_services'][
              'mentions'
              ];
        } else if (this.ready_influencers[index].network === 'tiktok') {
          this.totalTiktokers += 1;
          this.total_tiktok_videos += this.ready_influencers[index]['tiktok_services'][
              'tiktok_videos'
              ];
        }
      }
    }

  }

  setGeneralCampaignInfo() {
    this.total_influencers_impressions = this.totalImpressionsCampaign();
    this.setTotalCostReadyInfluencers();
    this.total_followers = this.followersCampaign();
    this.total_comments_campaign = this.totalCommentsCampaign();
    this.total_estimated_likes_campaign = this.totalLikesCampaign();
    this.cost_per_engagement = this.costPerEngagement();
    this.earn_media_value = this.earnMediaValue();
    this.totalMedias();
    this.avg_engagement_obj = this.engagementCampaign();
    this.avg_engagement_ig = this.avg_engagement_obj['eng_rate_ig'];
    this.avg_engagement_yt = this.avg_engagement_obj['eng_rate_yt'];
    this.avg_engagement_tiktok = this.avg_engagement_obj['eng_rate_tiktok'];
    this.totalImpressionsCalculatedCampaign();
    this.total_cost_per_mille = this.totalCostPerMilleCampaign();
    this.total_cost_per_mille_top_country = this.totalCostPerMilleTopCountry();
    this.total_est_reach = this.totalEstReach();
  }

  totalEstReach() {
    if (this.campaign.custom_total_estimated_impressions === 0 || this.campaign.custom_total_estimated_impressions === undefined) {
      return this.totalReach();
    } else {
      return this.totalReachCustomCalculated();
    }
  }


  totalReachCustomCalculated() {
    let result = 0;
  
    const calculateReach = (avgViews, serviceMultiplier, reachMultiplier) => {
      const calculatedReach = (avgViews * serviceMultiplier) * reachMultiplier;
      const percent = (avgViews * serviceMultiplier) / this.total_impressions_calculated;
      const extraImpressions = percent * (this.campaign.custom_total_estimated_impressions - this.total_impressions_calculated);
      const extraReach = extraImpressions * reachMultiplier;
      return calculatedReach + extraReach;
    };
  
    this.ready_influencers.forEach((influencer) => {
      if (influencer.state !== 'canceled' && influencer.state !== 'rejected') {
        switch (influencer.network) {
          case 'instagram':
            const reachPosts = calculateReach(influencer.est_impressions_posts, influencer.instagram_services.posts, 0.8865);
            const reachStories = calculateReach(influencer.est_impressions_stories, influencer.instagram_services.stories, 0.8235);
            result += reachPosts + reachStories;
            break;
          case 'youtube':
            const reachVideos = calculateReach(influencer.avg_views, influencer.youtube_services.videos, 0.9);
            const reachMentions = calculateReach(influencer.avg_views, influencer.youtube_services.mentions, 0.9);
            result += reachVideos + reachMentions;
            break;
          case 'tiktok':
            const reachTikTokVideos = calculateReach(influencer.avg_views, influencer.tiktok_services.tiktok_videos, 0.9);
            result += reachTikTokVideos;
            break;
          default:
            break;
        }
      }
    });
  
    return result;
  }
  

  // totalReachCustomCalculated() {
  //   let result = 0;
  //   let reach_calculated_posts = 0;
  //   let reach_calculated_stories = 0;
  //   let percent_posts;
  //   let percent_stories;
  //   let extra_impressions_posts;
  //   let extra_impressions_stories;
  //   let extra_reach_posts;
  //   let extra_reach_stories;
  //   /// youtube
  //   let reach_calculated_videos = 0;
  //   let reach_calculated_mentions = 0;
  //   let percent_videos;
  //   let percent_mentions;
  //   let extra_impressions_videos;
  //   let extra_impressions_mentions;
  //   let extra_reach_videos;
  //   let extra_reach_mentions;
  //   /// tiktok
  //   let reach_calculated_tiktok_videos = 0;
  //   let percent_tiktok_videos;
  //   let extra_impressions_tiktok_videos;
  //   let extra_reach_tiktok_videos;
  //   this.ready_influencers.forEach((influencer) => {
  //     if (
  //       influencer.state !== 'canceled' &&
  //       influencer.state !== 'rejected'
  //     ) {
  //       if (influencer.network === 'instagram') {
  //         reach_calculated_posts = (influencer.est_impressions_posts * influencer.instagram_services.posts) * 0.8865
  //         percent_posts = (influencer.est_impressions_posts * influencer.instagram_services.posts) / this.total_impressions_calculated;
  //         extra_impressions_posts = percent_posts * (this.campaign.custom_total_estimated_impressions - this.total_impressions_calculated);
  //         extra_reach_posts = extra_impressions_posts * 0.8865;
  //         reach_calculated_stories = (influencer.est_impressions_stories * influencer.instagram_services.stories) * 0.8235
  //         percent_stories = (influencer.est_impressions_stories * influencer.instagram_services.stories) / this.total_impressions_calculated;
  //         extra_impressions_stories = percent_stories * (this.campaign.custom_total_estimated_impressions - this.total_impressions_calculated);
  //         extra_reach_stories = extra_impressions_stories * 0.8235;
  //         result += (reach_calculated_posts + extra_reach_posts) + (reach_calculated_stories + extra_reach_stories);
  //       } else if (influencer.network === 'youtube') {
  //         reach_calculated_videos = (influencer.avg_views * influencer.youtube_services.videos) * 0.9
  //         percent_videos = (influencer.avg_views * influencer.youtube_services.videos) / this.total_impressions_calculated;
  //         extra_impressions_videos= percent_videos * (this.campaign.custom_total_estimated_impressions - this.total_impressions_calculated);
  //         extra_reach_videos = extra_impressions_videos * 0.9;
  //         reach_calculated_mentions = (influencer.avg_views * influencer.youtube_services.mentions) * 0.9
  //         percent_mentions = (influencer.avg_views * influencer.youtube_services.mentions) / this.total_impressions_calculated;
  //         extra_impressions_mentions = percent_mentions * (this.campaign.custom_total_estimated_impressions - this.total_impressions_calculated);
  //         extra_reach_mentions = extra_impressions_mentions * 0.9;
  //         result += (reach_calculated_videos + extra_reach_videos) + (reach_calculated_mentions + extra_reach_mentions);
  //       } else if (influencer.network === 'tiktok') {
  //         reach_calculated_tiktok_videos = (influencer.avg_views * influencer.tiktok_services.tiktok_videos) * 0.9
  //         percent_tiktok_videos = (influencer.avg_views * influencer.tiktok_services.tiktok_videos) / this.total_impressions_calculated;
  //         extra_impressions_tiktok_videos = percent_tiktok_videos * (this.campaign.custom_total_estimated_impressions - this.total_impressions_calculated);
  //         extra_reach_tiktok_videos = extra_impressions_tiktok_videos * 0.9;
  //         result += reach_calculated_tiktok_videos + extra_reach_tiktok_videos;
  //       }
  //     }
  // });
  // return result;
  // }

  totalReach() {
      let result = 0;
      // no es custom, lo devolvemos normal
      this.ready_influencers.forEach((influencer) => {
        if (
          influencer.state !== 'canceled' &&
          influencer.state !== 'rejected'
        ) {
          if (influencer.network === 'instagram') {
            result += (influencer.est_impressions_posts * influencer.instagram_services.posts) * 0.8865
            //codigo custom
            //paso 1 otengo el %
            result += (influencer.est_impressions_stories * influencer.instagram_services.stories) * 0.8235
          } else if (influencer.network === 'youtube') {
            result += (influencer.avg_views * influencer.youtube_services.videos) * 0.9
            result += (influencer.avg_views * influencer.youtube_services.mentions) * 0.9
          } else if (influencer.network === 'tiktok') {
            result += (influencer.avg_views * influencer.tiktok_services.tiktok_videos) * 0.9
          }
        }
    });
    return result;
  }

  setTotalCostReadyInfluencers() {
    let result_posts;
    let result_stories;
    // let result_reels;
    let result_mentions;
    let result_videos;
    let result_tiktok_videos;
    for (let i = 0; i < this.ready_influencers.length; i++) {
      if (this.ready_influencers[i].network === 'instagram') {
        result_posts =
            this.ready_influencers[i].instagram_services.posts *
            this.ready_influencers[i].price_per_post_plus_perc;
        result_stories =
            this.ready_influencers[i].instagram_services.stories *
            this.ready_influencers[i].price_per_story_plus_perc;
        this.ready_influencers[i].total_cost =
            (result_posts + result_stories) *
            this.ready_influencers[i].correction_index;
      } else if (this.ready_influencers[i].network === 'youtube') {
        result_mentions =
            this.ready_influencers[i].youtube_services.mentions *
            this.ready_influencers[i].price_per_mention_plus_perc;
        result_videos =
            this.ready_influencers[i].youtube_services.videos *
            this.ready_influencers[i].price_per_video_plus_perc;
        this.ready_influencers[i].total_cost =
            (result_mentions + result_videos) *
            this.ready_influencers[i].correction_index;
      } else if (this.ready_influencers[i].network === 'tiktok') {
        result_tiktok_videos =
            this.ready_influencers[i].tiktok_services.tiktok_videos *
            this.ready_influencers[i].price_per_tiktok_video_plus_perc;
        this.ready_influencers[i].total_cost =
            (result_tiktok_videos) *
            this.ready_influencers[i].correction_index;
      }
    }
    this.total_cost = this.is_share ? this.campaign.campaign_total_cost_share : this.totalCostCampaign() * this.campaign.campaign_margin_agency;
  }


  setInfluencersBackupTotalCost() {
    let result_posts;
    let result_stories;
    // let result_reels;
    let result_mentions;
    let result_videos;
    let result_tiktok_videos;
    for (let i = 0; i < this.backup_influencers.length; i++) {
      if (this.backup_influencers[i].network === 'instagram') {
        result_posts =
            this.backup_influencers[i].instagram_services.posts *
            this.backup_influencers[i].price_per_post_plus_perc;
        result_stories =
            this.backup_influencers[i].instagram_services.stories *
            this.backup_influencers[i].price_per_story_plus_perc;
        this.backup_influencers[i].total_cost =
            (result_posts + result_stories) *
            this.backup_influencers[i].correction_index;
      } else if (this.backup_influencers[i].network === 'youtube') {
        result_mentions =
            this.backup_influencers[i].youtube_services.mentions *
            this.backup_influencers[i].price_per_mention_plus_perc;
        result_videos =
            this.backup_influencers[i].youtube_services.videos *
            this.backup_influencers[i].price_per_video_plus_perc;
        this.backup_influencers[i].total_cost =
            (result_mentions + result_videos) *
            this.backup_influencers[i].correction_index;
      } else if (this.backup_influencers[i].network === 'tiktok') {
        result_tiktok_videos =
            this.backup_influencers[i].tiktok_services.tiktok_videos *
            this.backup_influencers[i].price_per_tiktok_video_plus_perc;
        this.backup_influencers[i].total_cost =
            (result_tiktok_videos) *
            this.backup_influencers[i].correction_index;
      }
    }
  }

  totalCostCampaign() {
    let result = 0;

    for (const influencer of this.ready_influencers) {
      if (influencer.state !== 'canceled' && influencer.state !== 'rejected') {
        const influencerCost = Number(influencer.total_cost);
        result += influencerCost;
      }
    }
    return result;
  }

  followersCampaign() {
    let influencer_followers;
    let result = 0;
    for (let i = 0; i < this.ready_influencers.length; i++) {
      if (
          this.ready_influencers[i].state !== 'canceled' &&
          this.ready_influencers[i].state !== 'rejected'
      ) {
        if (
            this.ready_influencers[i].campaign_posts !== 0 ||
            this.ready_influencers[i].campaign_stories !== 0
        ) {
          if (this.ready_influencers[i].network === 'instagram' || this.ready_influencers[i].network === 'tiktok') {
            influencer_followers = this.ready_influencers[i].followed_by_count;
            result += influencer_followers;
          } else if (this.ready_influencers[i].network === 'youtube') {
            influencer_followers = this.ready_influencers[i].subscribers_count;
            result += influencer_followers;
          }
        } else {
          result += 0;
        }
      }
    }
    return result;
  }

  earnMediaValue() {
    const IMPRESSIONS = this.total_influencers_impressions;
    const LIKES = this.total_estimated_likes_campaign;
    const COMMENTS = this.total_comments_campaign;
    let result;
    result = 250 + IMPRESSIONS * 0.12 + COMMENTS * 3.82 + LIKES * 0.32;
    result = result * this.campaign.currency_ratio;
    if (result === 250) {
      result = 0;
    }
    return result;
  }

  totalImpressionsCampaign() {
    // total impressions estimated, calculated with all influencers
      let influencer_impressions_posts;
      let influencer_impressions_stories;
      let influencer_post;
      let influencer_story;
      let result_influencer_impressions;
      let influencer_videos;
      let influencer_mentions;
      let influencer_impressions_mentions;
      let influencer_impressions_videos;
      let result = 0;
      for (let i = 0; i < this.ready_influencers.length; i++) {
        if (
            this.ready_influencers[i].state !== 'canceled' &&
            this.ready_influencers[i].state !== 'rejected'
        ) {
          if (this.ready_influencers[i].network === 'instagram') {
            influencer_impressions_posts = this.ready_influencers[i]
                .est_impressions_posts;
            influencer_impressions_stories = this.ready_influencers[i]
                .est_impressions_stories;
            influencer_post = this.ready_influencers[i].instagram_services.posts;
            influencer_story = this.ready_influencers[i].instagram_services.stories;
            result_influencer_impressions =
                influencer_impressions_posts * influencer_post +
                influencer_impressions_stories * influencer_story;
            result += result_influencer_impressions;
          } else if (this.ready_influencers[i].network === 'youtube') {
            influencer_impressions_mentions = this.ready_influencers[i].avg_views;
            influencer_impressions_videos = this.ready_influencers[i].avg_views;
            influencer_mentions = this.ready_influencers[i].youtube_services.mentions;
            influencer_videos = this.ready_influencers[i].youtube_services.videos;
            result_influencer_impressions =
                influencer_impressions_mentions * influencer_mentions +
                influencer_impressions_videos * influencer_videos;
            result += result_influencer_impressions;
          } else if (this.ready_influencers[i].network === 'tiktok') {
            influencer_impressions_videos = this.ready_influencers[i].avg_impressions;
            influencer_videos = this.ready_influencers[i].tiktok_services.tiktok_videos;
            result_influencer_impressions =
                influencer_impressions_videos * influencer_videos;
            result += result_influencer_impressions;
          }
        }
      }
    if (this.campaign.custom_total_estimated_impressions === 0 || this.campaign.custom_total_estimated_impressions === undefined) {
      return result;
    } else {
      return this.campaign.custom_total_estimated_impressions;
    }
  }


  totalImpressionsCalculatedCampaign() {
    // total impressions estimated, calculated with all influencers
      let influencer_impressions_posts;
      let influencer_impressions_stories;
      let influencer_post;
      let influencer_story;
      let result_influencer_impressions;
      let influencer_videos;
      let influencer_mentions;
      let influencer_impressions_mentions;
      let influencer_impressions_videos;
      let result = 0;
      for (let i = 0; i < this.ready_influencers.length; i++) {
        if (
            this.ready_influencers[i].state !== 'canceled' &&
            this.ready_influencers[i].state !== 'rejected'
        ) {
          if (this.ready_influencers[i].network === 'instagram') {
            influencer_impressions_posts = this.ready_influencers[i]
                .est_impressions_posts;
            influencer_impressions_stories = this.ready_influencers[i]
                .est_impressions_stories;
            influencer_post = this.ready_influencers[i].instagram_services.posts;
            influencer_story = this.ready_influencers[i].instagram_services.stories;
            result_influencer_impressions =
                influencer_impressions_posts * influencer_post +
                influencer_impressions_stories * influencer_story;
            result += result_influencer_impressions;
          } else if (this.ready_influencers[i].network === 'youtube') {
            influencer_impressions_mentions = this.ready_influencers[i].avg_views;
            influencer_impressions_videos = this.ready_influencers[i].avg_views;
            influencer_mentions = this.ready_influencers[i].youtube_services.mentions;
            influencer_videos = this.ready_influencers[i].youtube_services.videos;
            result_influencer_impressions =
                influencer_impressions_mentions * influencer_mentions +
                influencer_impressions_videos * influencer_videos;
            result += result_influencer_impressions;
          } else if (this.ready_influencers[i].network === 'tiktok') {
            influencer_impressions_videos = this.ready_influencers[i].avg_impressions;
            influencer_videos = this.ready_influencers[i].tiktok_services.tiktok_videos;
            result_influencer_impressions =
                influencer_impressions_videos * influencer_videos;
            result += result_influencer_impressions;
          }
        }
      }
      this.total_impressions_calculated = result;
  }



  totalLikesCampaign() {
    let influencer_likes;
    let influencer_post;
    let total_influencer_likes;
    let influencer_video;
    let influencer_mention;
    let result = 0;
    for (let i = 0; i < this.ready_influencers.length; i++) {
      if (
          this.ready_influencers[i].state !== 'canceled' &&
          this.ready_influencers[i].state !== 'rejected'
      ) {
        if (this.ready_influencers[i].network === 'instagram') {
          influencer_likes = this.ready_influencers[i].avg_likes;
          influencer_post = this.ready_influencers[i].instagram_services.posts;
          total_influencer_likes = influencer_likes * influencer_post;
          result += total_influencer_likes;
        } else if (this.ready_influencers[i].network === 'youtube') {
          influencer_video = this.ready_influencers[i].youtube_services.videos;
          influencer_mention = this.ready_influencers[i].youtube_services.mentions;
          influencer_likes = this.ready_influencers[i].avg_likes;
          total_influencer_likes =
              (influencer_video + influencer_mention) * influencer_likes;
          result += total_influencer_likes;
        } else if (this.ready_influencers[i].network === 'tiktok') {
          influencer_video = this.ready_influencers[i].tiktok_services.tiktok_videos;
          influencer_likes = this.ready_influencers[i].avg_likes;
          total_influencer_likes =
              influencer_video * influencer_likes;
          result += total_influencer_likes;
        }
      }
    }
    return result;
  }

  totalCommentsCampaign() {
    let influencer_ig_comments = 0;
    let influencer_post;
    let total_influencer_ig_comments;
    let result_ig = 0;

    let influencer_tiktok_comments = 0;
    let influencer_videos;
    let total_influencer_tiktok_comments;
    let result_tiktok = 0;


    let influencer_youtube_comments = 0;
    let influencer_youtube_videos;
    let influencer_youtube_mentions;
    let total_influencer_youtube_comments;
    let result_youtube = 0;

    for (let i = 0; i < this.ready_influencers.length; i++) {
      if (
          this.ready_influencers[i].state !== 'canceled' &&
          this.ready_influencers[i].state !== 'rejected'
      ) {
        if (this.ready_influencers[i].network === 'instagram') {
          if (this.ready_influencers[i].avg_comments) {
            influencer_ig_comments = this.ready_influencers[i].avg_comments;
          }
          influencer_post = this.ready_influencers[i].instagram_services.posts;
          total_influencer_ig_comments = influencer_ig_comments * influencer_post;
          result_ig += total_influencer_ig_comments;
        }
        if (this.ready_influencers[i].network === 'tiktok') {
          if (this.ready_influencers[i].avg_comments) {
            influencer_tiktok_comments = this.ready_influencers[i].avg_comments;
          }
          influencer_videos = this.ready_influencers[i].tiktok_services.tiktok_videos;
          total_influencer_tiktok_comments = influencer_tiktok_comments * influencer_videos;
          result_tiktok += total_influencer_tiktok_comments;
        }

        if (this.ready_influencers[i].network === 'youtube') {
          if (this.ready_influencers[i].avg_comments) {
            influencer_youtube_comments = this.ready_influencers[i].avg_comments;
          }
          influencer_youtube_videos = this.ready_influencers[i].youtube_services.videos;
          influencer_youtube_mentions = this.ready_influencers[i].youtube_services.mentions;
          total_influencer_youtube_comments = influencer_youtube_comments * (influencer_youtube_videos + influencer_youtube_mentions);
          result_youtube += influencer_youtube_comments;
        }
      }
    }
    return result_ig + result_tiktok + result_youtube;
  }

  engagementCampaign() {
    let result_influencer_numerator_ig;
    let result_influencer_denominator_ig;
    let result_ig = 0;
    let result_numerator_ig = 0;
    let result_denominator_ig = 0;

    let result_influencer_numerator_yt;
    let result_influencer_denominator_yt;
    let result_yt = 0;
    let result_numerator_yt = 0;
    let result_denominator_yt = 0;


    let result_influencer_numerator_tiktok;
    let result_influencer_denominator_tiktok;
    let result_tiktok = 0;
    let result_numerator_tiktok = 0;
    let result_denominator_tiktok = 0;

    this.ready_influencers.forEach(influencer => {
      if (influencer.network === 'instagram') {
        if (influencer.instagram_services.posts !== 0) {
          result_influencer_numerator_ig =
              (influencer.engagement_rate * 100) *
              influencer.followed_by_count *
              influencer.instagram_services.posts;
          result_influencer_denominator_ig =
              influencer.followed_by_count * influencer.instagram_services.posts;
        } else if (
            influencer.instagram_services.posts === 0 &&
            influencer.instagram_services.stories !== 0
        ) {
          result_influencer_numerator_ig =
              influencer.engagement_rate * influencer.followed_by_count;
          result_influencer_denominator_ig = influencer.followed_by_count;
        } else if (
            influencer.instagram_services.posts === 0 &&
            influencer.instagram_services.stories === 0
        ) {
          result_influencer_numerator_ig = 0;
          result_influencer_denominator_ig = 0;
        }

        result_numerator_ig += result_influencer_numerator_ig;
        result_denominator_ig += result_influencer_denominator_ig;
      } else if (influencer.network === 'youtube') {
        result_influencer_numerator_yt =
        (influencer.engagement_rate * 100)*
            influencer.avg_views *
            (influencer.youtube_services.videos +
                influencer.youtube_services.mentions);
        result_influencer_denominator_yt =
            influencer.avg_views *
            (influencer.youtube_services.videos +
                influencer.youtube_services.mentions);

        result_numerator_yt += result_influencer_numerator_yt;
        result_denominator_yt += result_influencer_denominator_yt;
      } else if (influencer.network === 'tiktok') {
        result_influencer_numerator_tiktok =
        (influencer.engagement_rate * 100)*
            influencer.followed_by_count *
            influencer.tiktok_services.tiktok_videos;
        result_influencer_denominator_tiktok =
            influencer.followed_by_count *
            influencer.tiktok_services.tiktok_videos;

        result_numerator_tiktok += result_influencer_numerator_tiktok;
        result_denominator_tiktok += result_influencer_denominator_tiktok;
      }
    });

    if (this.totalPost() !== 0 || this.totalStory() !== 0) {
      result_ig = result_numerator_ig / result_denominator_ig;
    }

    if (this.totalYoutubeVideo() !== 0 || this.totalMention() !== 0) {
      result_yt = result_numerator_yt / result_denominator_yt;
    }
    if (this.totalTiktokVideo() !== 0) {
      result_tiktok = result_numerator_tiktok / result_denominator_tiktok;
    }

    return { eng_rate_ig: result_ig, eng_rate_yt: result_yt, eng_rate_tiktok: result_tiktok };
  }

  totalPost() {
    let influencer_post;
    let result = 0;
    for (let i = 0; i < this.ready_influencers.length; i++) {
      if (
          this.ready_influencers[i].state !== 'canceled' &&
          this.ready_influencers[i].state !== 'rejected'
      ) {
        if (this.ready_influencers[i].network === 'instagram') {
          influencer_post = this.ready_influencers[i].campaign_posts;
          result += influencer_post;
        }
      }
    }

    return result;
  }

  totalYoutubeVideo() {
    let influencer_video;
    let result = 0;
    for (let i = 0; i < this.ready_influencers.length; i++) {
      if (
          this.ready_influencers[i].state !== 'canceled' &&
          this.ready_influencers[i].state !== 'rejected'
      ) {
        if (this.ready_influencers[i].network === 'youtube') {
          influencer_video = this.ready_influencers[i].youtube_services.videos;
          result += influencer_video;
        }
      }
    }
    return result;
  }

  totalTiktokVideo() {
    let influencer_video;
    let result = 0;
    for (let i = 0; i < this.ready_influencers.length; i++) {
      if (
          this.ready_influencers[i].state !== 'canceled' &&
          this.ready_influencers[i].state !== 'rejected'
      ) {
        if (this.ready_influencers[i].network === 'tiktok') {
          influencer_video = this.ready_influencers[i].tiktok_services.tiktok_videos;
          result += influencer_video;
        }
      }
    }
    return result;
  }

  totalMention() {
    let influencer_mention;
    let result = 0;
    for (let i = 0; i < this.ready_influencers.length; i++) {
      if (
          this.ready_influencers[i].state !== 'canceled' &&
          this.ready_influencers[i].state !== 'rejected'
      ) {
        if (this.ready_influencers[i].network === 'youtube') {
          influencer_mention = this.ready_influencers[i].youtube_services.mentions;
          result += influencer_mention;
        }
      }
    }

    return result;
  }

  totalStory() {
    let influencer_story;
    let result = 0;
    for (let i = 0; i < this.ready_influencers.length; i++) {
      if (
          this.ready_influencers[i].state !== 'canceled' &&
          this.ready_influencers[i].state !== 'rejected'
      ) {
        if (this.ready_influencers[i].network === 'instagram') {
          influencer_story = this.ready_influencers[i].campaign_stories;
          result += influencer_story;
        }
      }
    }

    return result;
  }

  totalCostPerMilleCampaign() {
    let result;
    if (this.total_influencers_impressions === 0) {
      result = 0;
    } else {
      if (this.is_share) {
        result = (this.total_cost / this.total_influencers_impressions) * 1000;
      } else {
        result = ((this.total_cost * this.campaign.campaign_margin_agency) / this.total_influencers_impressions) * 1000;
      }
    }
    return result;
  }

  totalCostPerMilleTopCountry() {
    let result;
    if (this.totalImpressionsCampaign() === 0) {
      result = 0;
    } else if (this.campaign.aggregated_audience) {
      if (
          !this.isEmptyObject(this.campaign.aggregated_audience.instagram) &&
          !this.isEmptyObject(this.campaign.aggregated_audience.instagram.location) &&
          !this.isEmptyObject(
              this.campaign.aggregated_audience.instagram.location.audience_country_locations
          )
      ) {
        result =
            ((this.total_cost * this.campaign.campaign_margin_agency) /
                (this.totalImpressionsCampaign() *
                    this.campaign.aggregated_audience.instagram.location
                        .audience_country_locations[0].value)) *
            1000;
      }
    }

    return result;
  }

  isEmptyObject(obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return true;
  }

  totalinteractionsCampaign() {
    let influencer_likes;
    let influencer_comments;
    let influencer_interactions;
    let influencer_posts;
    let influencer_mentions;
    let influencer_videos;
    let influencer_tiktok_videos;
    let total_influencer_interactions = 0;
    let influencer_medias;
    let result = 0;
    for (let i = 0; i < this.ready_influencers.length; i++) {
      if (
          this.ready_influencers[i].state !== 'canceled' &&
          this.ready_influencers[i].state !== 'rejected'
      ) {
        if (this.ready_influencers[i].network === 'instagram') {
          influencer_likes = this.ready_influencers[i].avg_likes;
          influencer_comments = this.ready_influencers[i].avg_comments;
          influencer_posts = this.ready_influencers[i].instagram_services.posts;
          influencer_interactions = influencer_likes + influencer_comments;
          total_influencer_interactions =
              influencer_interactions * influencer_posts;
          result += total_influencer_interactions;
        } else if (this.ready_influencers[i].network === 'youtube') {
          influencer_likes = this.ready_influencers[i].avg_likes;
          influencer_mentions = this.ready_influencers[i].youtube_services.mentions;
          influencer_videos = this.ready_influencers[i].youtube_services.videos;
          influencer_medias = influencer_mentions + influencer_videos;
          total_influencer_interactions = influencer_likes * influencer_medias;
          result += total_influencer_interactions;
        } else if (this.ready_influencers[i].network === 'tiktok') {
          influencer_likes = this.ready_influencers[i].avg_likes;
          influencer_comments = this.ready_influencers[i].avg_comments;
          influencer_tiktok_videos = this.ready_influencers[i].tiktok_services.tiktok_videos;
          influencer_interactions = influencer_likes + influencer_comments;
          total_influencer_interactions = influencer_interactions * influencer_tiktok_videos;
          result += total_influencer_interactions;
        }
      }
    }
    return result;
  }

  getCampaign() {
    this.callGetCampaign.emit();
  }

  costPerEngagement() {
    let result;
    const totalInteracctions = this.totalinteractionsCampaign();
    if (totalInteracctions !== 0) {
      result =
          (this.total_cost * this.campaign.campaign_margin_agency) /
          totalInteracctions;
    } else {
      result = 0;
    }

    return result;
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  openProfile(event) {
    this.profile_sidenav.toggle();

    const baseInfluencerData = {
      influencer_id: event.influencer_id,
      network: event.network,
      currency_type: this.currency_type,
      currency_symbol: this.currency_symbol
    };

    if (this.is_share) {
      this.bindable_influencer_data = {
        ...baseInfluencerData,
        origin: 'share_campaign_plan',
        shared_campaign_plan_hash: this.campaign_id
      };
    } else {
      this.bindable_influencer_data = {
        ...baseInfluencerData,
        origin: 'campaign_plan',
        campaign_id: this.campaign_id
      };
    }

  }

  createBrief() {
    this.subscriptions.push(
        this._campaignsService
            .createBrief(this.campaign_id)
            .subscribe(() => {
              this.router.navigate([
                '/app/brief/' + this.campaign_id,
              ]);
              this.loading = false;
              this.snackBar.openFromComponent(CustomSnackbarComponent, {
                data: { message:  this.translate.instant('campaigns.contactCreated'), type: 'success' },
                duration: 3000,
                horizontalPosition: 'right',
                verticalPosition: 'top',
                panelClass: 'success-snackbar',
              });
            }, () => {
              this.loading = false;
              this.snackBar.openFromComponent(CustomSnackbarComponent, {
                data: { message: this.translate.instant('shareCampaignPlanModal.oopsError'), type: 'error' },
                duration: 3000,
                horizontalPosition: 'right',
                verticalPosition: 'top',
                panelClass: 'error-snackbar',
              });
            })
    );
  }

  updateUniqueAudience() {
    this.loading = true;
    this.subscriptions.push(
        this._campaignsService.updateAudienceOverlap(this.campaign_id).subscribe( response => {
          this.loading = false;
          if (response.body.message !== 'Unique audience completed. Not enough influencers from instagram to analyze unique audience') {
            this.getCampaign();
          }
          if (response.status === 207) {
            this.snackBar.openFromComponent(CustomSnackbarComponent, {
              data: { message: response.body.message, type: 'warning' },
              duration: 3000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              panelClass: 'warning-snackbar',
            });
          } else if (response.status === 200) {
            this.snackBar.openFromComponent(CustomSnackbarComponent, {
              data: { message: response.body.message, type: 'success' },
              duration: 3000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              panelClass: 'success-snackbar',
            });
          }
        }, error => {
          this.loading = true;
          let errorMessage = 'Oops something went wrong, please try again.';
          if (error.error.message) {
            errorMessage = error.error.message;
          }
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: errorMessage, type: 'error' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'error-snackbar',
          });
          this.loading = false;
        })
    );
  }

}

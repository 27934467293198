import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {RecaptchaFormsModule} from 'ng-recaptcha/forms';
import {IonRangeSliderModule} from 'ng2-ion-range-slider';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {ChartsModule} from 'ng2-charts';
import {RouterModule} from '@angular/router';

import {AngularMaterialModule} from '../angular-material.module';
// Pipes
import {CutStringPipe} from './pipes/cut-string.pipe';
import {HitPipePipe} from './pipes/hit-pipe.pipe';
import {PerCentSymbolPipe} from './pipes/percent-symbol.pipe';
import {ConverterTimeToHourDate} from './pipes/converter-time-to-hour-date.pipe';
// Components
import {PerMilleSymbolPipe} from './pipes/per-mille-symbol.pipe';
import {ConverterKMPipe} from './pipes/converter-k-m.pipe';
import {ToFixedPipe} from './pipes/to-fixed.pipe';
import {RecaptchaModule} from 'ng-recaptcha';
import {InfluencerCardComponent} from './components/influencer/influencer-card/influencer-card.component';
import {InfluencerProfileComponent} from './components/influencer/influencer-profile/influencer-profile.component';
import {InfluencerInfoComponent} from './components/influencer/influencer-info/influencer-info.component';
import {InfluencerAvatarComponent} from './components/influencer/influencer-avatar/influencer-avatar.component';
import {SpinnerComponent} from 'src/app/components/share/spinner/spinner.component';
// TODO Components to refactor
import {NavbarComponent} from 'src/app/components/share/navbar/navbar.component';
import {SidenavInfluencerProfileComponent} from 'src/app/components/private/campaigns/sidenav-influencer-profile/sidenav-influencer-profile.component';
import {ProgressProposalCampaignComponent} from '../../components/private/campaigns/progress-campaign/components/progress-proposal-campaign/progress-proposal-campaign.component';
import {InfluencerProposalsModalComponent} from '../../components/private/campaigns/progress-campaign/modals/influencer-proposals-modal/influencer-proposals-modal.component';
import {ShowStoryModalComponent} from 'src/app/components/private/campaigns/show-story-modal/show-story-modal.component';
import {ReportInfluencerContentModalComponent} from 'src/app/components/private/campaigns/report-campaign/report-influencer-content-modal/report-influencer-content-modal.component';
import {CampaignsModalComponent} from 'src/app/components/private/campaigns/campaigns-modal/campaigns-modal.component';
import {NewCampaignComponent} from 'src/app/components/private/campaigns/new-campaign/new-campaign.component';
import {AnalyzeAudienceInfluencerComponent} from 'src/app/components/share/modals/analyze-audience-influencer/analyze-audience-influencer.component';
import {AddYoutuberModalComponent} from 'src/app/components/private/cards/add-youtuber-modal/add-youtuber-modal.component';
import {AnalyzeInfluencerComponent} from 'src/app/components/share/modals/analyze-influencer/analyze-influencer.component';
import {ModalRejectReasonComponent} from '../../components/share/shared-campaign/modals/modal-reject-reason/modal-reject-reason.component';
import {ProgressCampaignComponent} from '../../components/private/campaigns/progress-campaign/progress-campaign.component';
import {ModalConfirmRemoveProposalComponent} from '../../components/private/campaigns/progress-campaign/modals/influencer-proposals-modal/modals/modal-confirm-remove-proposal/modal-confirm-remove-proposal.component';
import {ProgressRejectProposalModalComponent} from '../../components/private/campaigns/progress-campaign/modals/progress-reject-proposal-modal/progress-reject-proposal-modal.component';
import {ModalPlansComponent} from '../modal-plans/components/modal-plans/modal-plans.component';
import {ModalPlans} from '../modal-plans/modal-plans.module';
import {SimilarInfluencersComponent} from './components/similar-influencers/similar-influencers.component';
import {TopInfluencerMediasComponent} from './components/report/top-influencer-medias/top-influencer-medias.component';
import {GaugeChartModule} from 'angular-gauge-chart';
import {ReportInfluencerContentHeaderComponent} from './components/report-influencer-content/report-influencer-content-header/report-influencer-content-header.component';
import {ReportInfluencerContentMediasComponent} from './components/report-influencer-content/report-influencer-content-medias/report-influencer-content-medias.component';
import {ReportInfluencerContentStatsComponent} from './components/report-influencer-content/report-influencer-content-stats/report-influencer-content-stats.component';
import {ClicksCpaChartComponent} from './components/charts/clicks-cpa-chart/clicks-cpa-chart.component';
import {TableActivityFeedComponent} from './components/report/table-activity-feed/table-activity-feed.component';
import {PageLoadsSessionsChartComponent} from './components/charts/page-loads-sessions-chart/page-loads-sessions-chart.component';
import {CollaboratedBrandsComponent} from './components/collaborated-brands/collaborated-brands.component';
import {ModalShareInfluencerGenerateLinkComponent} from '../private/modals/modal-share-influencer-generate-link/modal-share-influencer-generate-link.component';
import {TotalsComponent} from './components/report/totals/totals.component';
import {StatsComponent} from './components/report/stats/stats.component';
import {ReportHeaderComponent} from '../../components/private/campaigns/report-campaign/components/report-header/report-header.component';
import {ReportInfluencersComponent} from '../../components/private/campaigns/report-campaign/components/report-influencers/report-influencers.component';
import {AddPostManuallyComponent} from '../../components/private/campaigns/add-post-manually/add-post-manually.component';
import {ModalUploadStoriesManuallyComponent} from '../../components/private/campaigns/report-campaign/report-influencer-content-modal/modal-upload-stories-manually/modal-upload-stories-manually.component';
import {LateralProfilePresentationComponent} from './components/influencer-lateral-profile/lateral-profile-presentation/lateral-profile-presentation.component';
import {LateralProfileInfluencerComponent} from './components/influencer-lateral-profile/lateral-profile-influencer/lateral-profile-influencer.component';
import {DoughnutChartComponent} from './components/influencer-lateral-profile/charts/doughnut-chart/doughnut-chart.component';
import {ProgressSpinnerChartComponent} from './components/influencer-lateral-profile/charts/progress-spinner-chart/progress-spinner-chart.component';
import {InfluencerLookalikesComponent} from './components/influencer-lateral-profile/lateral-profile-influencer/components/influencer-lookalikes/influencer-lookalikes.component';
import {LineChartComponent} from './components/influencer-lateral-profile/charts/line-chart/line-chart.component';
import {InfluencerLineChartsComponent} from './components/influencer-lateral-profile/lateral-profile-influencer/components/influencer-line-charts/influencer-line-charts.component';
import {InfluencerProfileStatsComponent} from './components/influencer-lateral-profile/lateral-profile-influencer/components/influencer-profile-stats/influencer-profile-stats.component';
import {BarChartComponent} from './components/influencer-lateral-profile/charts/bar-chart/bar-chart.component';
import {LateralProfileAudienceComponent} from './components/influencer-lateral-profile/lateral-profile-audience/lateral-profile-audience.component';
import {InterestsAffinityComponent} from './components/influencer-lateral-profile/components/interests-affinity/interests-affinity.component';
import {HorizontalGenderChartComponent} from './components/influencer-lateral-profile/charts/horizontal-gender-chart/horizontal-gender-chart.component';
import {LateralProfileTapsComponent} from './components/influencer-lateral-profile/components/lateral-profile-taps/lateral-profile-taps.component';
import {SponsoredPostsComponent} from './components/influencer-lateral-profile/components/sponsored-posts/sponsored-posts.component';
import {ViewMoreModalComponent} from './components/influencer-lateral-profile/modals/view-more-modal/view-more-modal.component';
import {ContactsComponent} from './components/influencer-lateral-profile/components/contacts/contacts.component';
import {ModalInfluencerDataInfoComponent} from '../../components/share/shared-campaign/modals/modal-influencer-data-info/modal-influencer-data-info.component';
import {ObtenKeysPipe} from './pipes/obten-keys.pipe';
import {TranslateModule} from '@ngx-translate/core';
import {DoughnutValueEmptyComponent} from './components/charts/doughnut-value-empty/doughnut-value-empty.component';
import {CurrencyReduxPipe} from './pipes/currency-redux.pipe';
import {HeaderAiDropdownBarComponent} from './components/ai/header-ai-dropdown-bar/header-ai-dropdown-bar.component';
import {AiChatComponent} from './components/ai/ai-chat/ai-chat.component';
import {TopContentReportCommentComponent} from '../../components/private/campaigns/report-campaign/components/top-content-report-comment/top-content-report-comment.component';
import {GeneralReportCommentComponent} from '../../components/private/campaigns/report-campaign/components/general-report-comment/general-report-comment.component';
import {ConverterKMImpactsPipe} from './pipes/converter-k-m-impacts.pipe';
import {RoundPercentageInputPipe} from './pipes/round-percentage-input.pipe';
import {LocationsByCountryComponent} from './components/charts/locations-by-country/locations-by-country.component';
import {LocationsByCityComponent} from './components/charts/locations-by-city/locations-by-city.component';
import {GenderChartComponent} from './components/charts/gender-chart/gender-chart.component';
import {AgeChartComponent} from './components/charts/age-chart/age-chart.component';
import {LanguagesChartComponent} from './components/charts/languages-chart/languages-chart.component';
import {InterestAffinityChartComponent} from './components/charts/interest-affinity-chart/interest-affinity-chart.component';
import {ReachabilityVerticalProgressComponent} from './components/charts/reachability-vertical-progress/reachability-vertical-progress.component';
import {CampaignPlanGeneralInfoComponent} from '../../components/private/campaigns/detail-campaign/components/campaign-plan-general-info/campaign-plan-general-info.component';
import {CampaignPlanStatsComponent} from '../../components/private/campaigns/detail-campaign/components/campaign-plan-stats/campaign-plan-stats.component';
import {CampaignPlanInfluencersComponent} from '../../components/private/campaigns/detail-campaign/components/campaign-plan-influencers/campaign-plan-influencers.component';
import {CampaignInfluencersRowComponent} from '../../components/private/campaigns/detail-campaign/components/campaign-plan-influencers/components/campaign-influencers-row/campaign-influencers-row.component';
import {CampaignInfluencersCardComponent} from '../../components/private/campaigns/detail-campaign/components/campaign-plan-influencers/components/campaign-influencers-card/campaign-influencers-card.component';
import {CustomSnackbarComponent} from './components/custom-snackbar/custom-snackbar.component';
import {PublicStepperComponent} from './components/steppers/public-stepper/public-stepper.component';
import {PrivateStepperComponent} from './components/steppers/private-stepper/private-stepper.component';
import {ControllerCampaignComponent} from './components/controllers/controller-campaign/controller-campaign.component';
import { ShowReasonInfluencerRejectedModalComponent } from 'src/app/components/share/shared-campaign/modals/show-reason-influencer-rejected-modal/show-reason-influencer-rejected-modal.component';
import { MarkdownPipe } from './pipes/markdown.pipe';
import { NewCampaignStep1Component } from 'src/app/components/private/campaigns/new-campaign-step1/new-campaign-step1.component';

const PIPES = [
  ConverterKMPipe,
  CutStringPipe,
  HitPipePipe,
  PerCentSymbolPipe,
  PerMilleSymbolPipe,
  ToFixedPipe,
  ConverterTimeToHourDate,
  CurrencyReduxPipe,
  ConverterKMImpactsPipe,
  MarkdownPipe
];

const COMPONENTS = [
  SpinnerComponent,
  NavbarComponent,
  SidenavInfluencerProfileComponent,
  ProgressProposalCampaignComponent,
  InfluencerCardComponent,
  InfluencerProfileComponent,
  InfluencerProfileComponent,
  InfluencerInfoComponent,
  InfluencerAvatarComponent,
  CampaignsModalComponent,
  ModalRejectReasonComponent,
  ShowReasonInfluencerRejectedModalComponent,
  ProgressRejectProposalModalComponent,
  ModalConfirmRemoveProposalComponent,
  TopInfluencerMediasComponent,
  ModalShareInfluencerGenerateLinkComponent,
  ReportHeaderComponent,
  ReportInfluencersComponent,
  CampaignPlanGeneralInfoComponent,
  CampaignPlanStatsComponent,
  CampaignPlanInfluencersComponent,
  CampaignInfluencersRowComponent,
  CampaignInfluencersCardComponent,
  LateralProfilePresentationComponent,
  LateralProfileInfluencerComponent,
  DoughnutChartComponent,
  ProgressSpinnerChartComponent,
  InfluencerLookalikesComponent,
  LineChartComponent,
  InfluencerLineChartsComponent,
  InfluencerProfileStatsComponent,
  BarChartComponent,
  LateralProfileAudienceComponent,
  SponsoredPostsComponent,
  InterestsAffinityComponent,
  HorizontalGenderChartComponent,
  ContactsComponent,
  ModalInfluencerDataInfoComponent,
  TopContentReportCommentComponent,
  GeneralReportCommentComponent,
  LocationsByCountryComponent,
  LocationsByCityComponent,
  GenderChartComponent,
  AgeChartComponent,
  LanguagesChartComponent,
  InterestAffinityChartComponent,
  PrivateStepperComponent,
  PublicStepperComponent,
];

const EXPORT_MODULES = [
  CommonModule,
  ReactiveFormsModule,
  RecaptchaModule,
  RecaptchaFormsModule,
  RecaptchaModule,
  IonRangeSliderModule,
  ScrollingModule,
  ChartsModule,
  ReportInfluencerContentHeaderComponent,
  ReportInfluencerContentMediasComponent,
  ReportInfluencerContentStatsComponent,
  ClicksCpaChartComponent,
  TableActivityFeedComponent,
  PageLoadsSessionsChartComponent,
  CollaboratedBrandsComponent,
  TotalsComponent,
  StatsComponent,
  LateralProfileTapsComponent,
  ObtenKeysPipe,
  DoughnutValueEmptyComponent,
  HeaderAiDropdownBarComponent,
  RoundPercentageInputPipe,
  ReachabilityVerticalProgressComponent,
  ControllerCampaignComponent
];

const ENTRY_COMPONENTS = [
  InfluencerProposalsModalComponent,
  ShowStoryModalComponent,
  ReportInfluencerContentModalComponent,
  AddPostManuallyComponent,
  ModalUploadStoriesManuallyComponent,
  CampaignsModalComponent,
  NewCampaignComponent,
  NewCampaignStep1Component,
  AnalyzeAudienceInfluencerComponent,
  AddYoutuberModalComponent,
  AnalyzeInfluencerComponent,
  ModalRejectReasonComponent,
  ShowReasonInfluencerRejectedModalComponent,
  ModalConfirmRemoveProposalComponent,
  ProgressRejectProposalModalComponent,
  ModalShareInfluencerGenerateLinkComponent,
  ViewMoreModalComponent,
  ModalInfluencerDataInfoComponent,
  CustomSnackbarComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...ENTRY_COMPONENTS,
    ...PIPES,
    SimilarInfluencersComponent,
    ReportInfluencerContentHeaderComponent,
    ReportInfluencerContentMediasComponent,
    ReportInfluencerContentStatsComponent,
    ClicksCpaChartComponent,
    TableActivityFeedComponent,
    PageLoadsSessionsChartComponent,
    CollaboratedBrandsComponent,
    TotalsComponent,
    StatsComponent,
    LateralProfileTapsComponent,
    ObtenKeysPipe,
    DoughnutValueEmptyComponent,
    HeaderAiDropdownBarComponent,
    AiChatComponent,
    RoundPercentageInputPipe,
    ReachabilityVerticalProgressComponent,
    CustomSnackbarComponent,
    PublicStepperComponent,
    PrivateStepperComponent,
    ControllerCampaignComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AngularMaterialModule,
    ChartsModule,
    ReactiveFormsModule,
    ModalPlans,
    GaugeChartModule,
    ScrollingModule,
    TranslateModule,
  ],
  exports: [...EXPORT_MODULES, ...COMPONENTS, ...PIPES],
  providers: [ProgressCampaignComponent, CurrencyPipe],
  entryComponents: [
    ...ENTRY_COMPONENTS,
    ModalPlansComponent,
  ],
})
export class SharedModule {}

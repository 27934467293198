import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { start } from 'repl';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  private campaignsUrl = environment.aws_resources.lambda_endpoint.api;

  constructor(private http: HttpClient) {
  }

  public getCampaigns(customer_email?, influencer_id?) {
    // Normal list campaigns
    if (influencer_id === undefined && customer_email === undefined) {
      return this.http.post(this.campaignsUrl + 'campaigns/list', { observe: 'response' }).pipe(map((data: any) => {
        return data;
      }));
      // list campaigns through influencer_id for view if added in some campaign
    } else if (customer_email === undefined && influencer_id !== undefined) {
      return this.http.get(this.campaignsUrl + 'campaigns/list/' + influencer_id).pipe(map((data: any) => {
        return data;
      }));
    }
  }

  public getInfluencersAnalyzedInfo(influencer_ids) {
    const body = {
      influencer_ids: influencer_ids,
    };
    return this.http.post(this.campaignsUrl + 'campaigns' + '/analyze-estimation', body, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  getBinfluCampaigns(emails) {
    return this.http.post(this.campaignsUrl + 'campaigns/list', emails, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  public createCampaign(campaign_name, currency, date_start, date_end, flowSelected, optionalParams?) {
    // Convertir las fechas a timestamp en segundos
    const startDateTimestamp = Math.floor(new Date(date_start).getTime() / 1000);
    const endDateTimestamp = Math.floor(new Date(date_end).getTime() / 1000);
    // Crear el cuerpo básico
    const body: any = {
      campaign_name: campaign_name,
      currency: currency,
      date_start: startDateTimestamp,
      date_end: endDateTimestamp,
      flow_selected: flowSelected
    };

    // Agregar parámetros opcionales si existen
    if (optionalParams && Object.keys(optionalParams).length > 0) {
      Object.assign(body, optionalParams);
    }


    // Realizar la solicitud HTTP
    return this.http.post(this.campaignsUrl + 'campaigns', body, { observe: 'response' }).pipe(
      map((data: any) => {
        return data;
      })
    );
  }


  public addInfluencerToCampaign(campaign_id, influencers) {
    const body = {
      influencers: influencers
    };
    return this.http.post(this.campaignsUrl + 'campaigns/' + campaign_id + '/influencer', body, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  public getCampaign(campaign_id) {
    return this.http.get(this.campaignsUrl + 'campaigns/' + campaign_id, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  public getCampaignPresentationThemes(campaign_id) {
    return this.http.get(this.campaignsUrl + 'campaigns/' + campaign_id + '/presentation-config', { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  updateConfigurationPresentation(campaign_id, request) {
    let body = {};
    if (request.influencers) {
      body = {
        "influencers": request.influencers
      };
    } else {
      body = {
        "template": request.template,
        "primary_color": request.primary_color,
        "secondary_color": request.secondary_color,
        "custom_logo": request.custom_logo,
        "default_configuration": request.default_configuration
      };
    }

    return this.http.put(this.campaignsUrl + 'campaigns/' + campaign_id + '/save-presentation', body, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  public updateAudienceOverlap(campaign_id) {
    const body = {};
    return this.http.post(this.campaignsUrl + 'campaigns/audience_overlap/' + campaign_id, body, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  updateCorrectionIndex(campaign_id, influencers) {
    return this.http.post(this.campaignsUrl + 'campaigns/' + campaign_id + '/correction_index', influencers, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  public removeInfluencerFromCampaign(campaign_id, influencer_ids) {
    const body = {
      influencer_ids: influencer_ids
    };
    return this.http.post(this.campaignsUrl + 'campaigns/' + campaign_id + '/remove-influencer', body, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }
  
  acceptInfluencer(campaign_id, influencer_id) {
    const body = {};
    return this.http.post(this.campaignsUrl + 'campaigns/' + campaign_id + '/influencer/' + influencer_id, body, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  updateCreateCampaignModal(campaign_id: string, campaign_config: any) {
    return this.http.put(this.campaignsUrl + 'campaigns/' + campaign_id, campaign_config, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  hideInfluencerCampaign(campaign_id, influencer_ids, hide_influencers?) {
    const body = {
      influencer_ids,
      hide_influencers
    };
    return this.http.put(this.campaignsUrl + 'campaigns/' + campaign_id + '/hide_influencers', body, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  updateCampaignPostsStories(campaign_id, influencers: Array<any>) {
    const body = {
      influencers: influencers
    };
    return this.http.put(this.campaignsUrl + 'campaigns/' + campaign_id, body, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  createBrief(campaign_id) {
    const body = {
      campaign_id: campaign_id
    };
    return this.http.post(this.campaignsUrl + 'brief/' + campaign_id, body, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }


  deleteCampaign(campaign_id) {
    return this.http.delete(this.campaignsUrl + 'campaigns/' + campaign_id, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  public shareCampaign(campaign_id, margin_agency, total_cost) {
    const body = {
      platform_id: environment.platform_id,
    };
    let params = new HttpParams();
    params = params.append('campaign_margin_agency', margin_agency);
    params = params.append('campaign_total_cost_share', total_cost);
    return this.http.post(this.campaignsUrl + 'campaigns/' + campaign_id + '/share', body, { observe: 'response', params }).pipe(map((data: any) => {
      return data;
    }));
  }

  public getSharedCampaign(hash_campaign_id) {
    return this.http.get(this.campaignsUrl + 'campaigns/shared/' + hash_campaign_id, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }



  updateTotalEstimatedImpressions(campaign_id, impressions) {
    const body = {};
    return this.http.post(this.campaignsUrl + 'campaigns/' + campaign_id + '/custom_total_estimated_impressions/' + impressions, body, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  updateInfluencerState(campaign_id, influencers) {
    const body = {
      influencers
    };
    return this.http.put(this.campaignsUrl + 'campaigns/' + campaign_id + '/switch-backup', body, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  shareCampaignRejectReason(campaign_hash, influencerIds, cancel_reason) {
    const body = {
      cancel_reason: cancel_reason,
      influencer_ids: influencerIds
    };
    return this.http.post(this.campaignsUrl + 'campaigns/shared/' + campaign_hash + '/reject-influencer', body, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  shareCampaignApproveInfluencer(campaign_hash, influencerIds) {
    const body = {
      influencer_ids: influencerIds
    };
    return this.http.post(this.campaignsUrl + 'campaigns/shared/' + campaign_hash + '/approved-influencer', body, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  adminGetAudience(campaignId, influencerId) {
    return this.http.get(this.campaignsUrl + 'campaigns/admin_get_influencer/' + campaignId + '/influencer/' + influencerId, { observe: 'response' });
  }

  adminUpdateAudience(campaignId, influencerId, audience) {
    return this.http.put(this.campaignsUrl + 'campaigns/edit_influencer_stats/' + campaignId + '/influencer/' + influencerId, audience, { observe: 'response' });
  }


  upgradeDetailCampaign(campaignId, influencerId, isPublic) {
    const body = {};
    let params = new HttpParams();
    params = isPublic ? params.append('campaign_hash', campaignId) : params.append('campaign_id', campaignId);
    params = params.append('influencer_id', influencerId);
    return this.http.post(this.campaignsUrl + 'influencer/upgrade_campaign', body, { observe: 'response', params }).pipe(map((data: any) => {
      return data;
    }));
  }

  approveInfluencer(campaignId, influencerIds) {
    const body = {
      influencer_ids: influencerIds
    };
    return this.http.post(this.campaignsUrl + 'campaigns/' + campaignId + '/approved-influencer', body, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  cancelInfluencer(campaignId, influencerIds, cancel_reason) {
    const body = {
      cancel_reason: cancel_reason,
      influencer_ids: influencerIds
    };
    return this.http.post(this.campaignsUrl + 'campaigns/' + campaignId + '/reject-influencer', body, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  updateCampaignLogo(campaign_id: string, body: any) {
    return this.http.put(this.campaignsUrl + 'campaigns/update_logo/' + campaign_id, body, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  exportExcel(campaignId, campaign, presentation_data) {
    const body = {
      campaign: campaign,
      presentation_data: presentation_data
    };
    return this.http.post(this.campaignsUrl + 'campaigns/' + campaignId + '/export', body, { observe: 'response' });
  }

  exportPpt(campaignId, campaign_data, configurationStyles, presentation_data) {
    const body = {
      "campaign_data": campaign_data,
      "presentation_config": configurationStyles,
      "presentation_data": presentation_data
    };
    return this.http.post(this.campaignsUrl + 'campaigns/' + campaignId + '/generate-presentation', body, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

  unlockCreatePresentation() {
    const body = {
      "limit_name": "GENERATE_CAMPAIGN_PRESENTATION"
    };
    return this.http.post(this.campaignsUrl + 'plans/request-open-limit', body, { observe: 'response' }).pipe(map((data: any) => {
      return data;
    }));
  }

}